import { FaSignOutAlt } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice'
import { Container } from "react-bootstrap"
import { SidebarMenu } from './kw-grouping/components'
function Header() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user,  isSuccess } = useSelector((state) => state.auth)
  const onLogout = () => {
    dispatch(logout())
    dispatch(reset())
    navigate('/login')
  }

  return (
    <Container fluid className='custom-padding'>
    <header className='header'>
      <div className='link-unstyled'>
        <Link to='/'>Keywords</Link>
      </div>
      <ul>
        {user ? (
          <li>
            <button className='btn btn-dark no-radius' onClick={onLogout}>
              <FaSignOutAlt /> Logout
            </button>
          </li>
        ) : (
          <>
            <span>
              User
            </span>
          </>
        )}
      </ul>
    </header>
    <SidebarMenu user={user} isSuccess={isSuccess} />
    </Container>
  )
}

export default Header
