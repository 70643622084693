import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import Spinner from "../components/Spinner";
import ProjectList from "../components/ProjectList";

function Dashboard({ socket }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }

    return () => {
      // dispatch(reset())
    };
  }, [user, navigate, dispatch]);

  return (
    <>
        <ProjectList socket={socket} />
    </>
  );
}

export default Dashboard;
