import React, { useEffect, useMemo, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import labelService from '../../../features/labels/labelService';
import { toast } from 'react-toastify';
import clickService from '../../shared/cells/ClickableCell';
import { useDispatch, useSelector } from 'react-redux';
import { deleteLabel, deleteSelectedLabels, handleDeleteLabels, selectLabels } from '../../../features/labels/labelSlice';
import { useParams } from 'react-router-dom';
import LabelDeleteModal from '../../shared/modals/LabelDeleteModal';
import { formatNumber } from '../../../utils/helper';
import { DEFAULT_LIMIT_UGKWS } from '../../../utils/constants';


const LabelItem = ({ label, projectId, socket, search, keywordWithAPI }) => {
    const subLabels = useRef("");
    const params = useParams();
    const [isEdit, setIsEdit] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState(false);
    const [isEditSubLabel, setIsEditSubLabel] = useState(false);
    const [labelName, setLabelName] = useState(label.labelName);
    const [showModal, setShowModal] = useState(false)
    const { selectedLabels } = useSelector((state) => state.labels)
    const dispatch = useDispatch()
    const [limit] = useState((JSON.parse(localStorage.getItem('paginationSetting'))?.ungroup?.limit) || DEFAULT_LIMIT_UGKWS)
    const [page] = useState((JSON.parse(localStorage.getItem('paginationSetting'))?.ungroup?.page) || 1)
    const [sort] = useState((JSON.parse(localStorage.getItem('sorting'))?.sort))
    const [sortBy] = useState((JSON.parse(localStorage.getItem('sorting'))?.sortBy))

    useEffect(() => {
        // socket.on("CREATE_LABEL", () => {
        //     dispatch(getLabels({id : params.id, searchParams :qs.stringify({search : search.current.value})}))
        // })
        // socket.on("CREATE_SUB_LABEL", (payload) => {
        //     dispatch(getLabels({id : params.id, searchParams :qs.stringify({search : search.current.value})}))
        // })
        socket.on("DELETE_LABEL", (payload) => {
            dispatch(handleDeleteLabels(payload.data))
        })
        
        return () => {
            // socket.off(`CREATE_LABEL`);
            // socket.off("CREATE_SUB_LABEL");
            // socket.off("DELETE_LABEL");
        }
    }, [])

    useEffect(() => {
        setLabelName(label.labelName)
    }, [label.labelName])

    const ActionCellSimple = ({ row }) => {
        return (
            <div className='d-flex justify-content-center align-items-center p-0'>
                <button onClick={() => handleEditSubLabel(row)} className={`btn-block-label  mb-0`}></button>
                <button onClick={() => handleDeleteSubLabel(row)} className={`btn-block-label  bg-yellow mb-0 ml-1`}></button>
            </div>
        );
    };

    const columns = useMemo(() => [
        {
            name: "Label",
            style: { color: "#3a3a3a" },
            cell: (row) => <clickService.ClickableCellForLabels row={row} selectedRowId={selectedRowId} ifEdit={isEditSubLabel} val={row.name} type={'text'} saveAction={saveSublabel} name={"name"} />,
        },
        {
            name: "#",
            selector: (row) => formatNumber(Number(row.count), 'count'),
            // sortable: true,
            style: { color: "#3a3a3a" }
        },
        {
            name: "Vol",
            selector: (row) => formatNumber(Number(row.volume), 'volume'),
            // sortable: true,
            style: { color: "#3a3a3a" }
        },
        {
            name: "CPC",
            selector: (row) => row.cpc > 0 ? Number(row.count) > 0 ? formatNumber((Number(row.cpc) / Number(row.count) || 0), 'cpc') :
            <span class="infinity-symbol">&#8734;</span> : 0,
            // sortable: true,
            style: { color: "#3a3a3a" }
        },
        {
            name: "FS",
            selector: (row) => formatNumber(Number(row.fs || 0), 'fs'),
            // sortable: true,
            style: { color: "#3a3a3a" }
        },
        {
            name: "Diff",
            selector: (row) => Math.round(row.difficulty) > 0 ? Number(row.count) > 0 ? formatNumber((parseFloat(Number(row.difficulty)) / Number(row.count)),'diff') : 
            <span class="infinity-symbol">&#8734;</span> : 0,
            // sortable: true,
            style: { color: "#3a3a3a" }
        },
        {
            name: "V/D",
            selector: (row) => Math.round(row.volRatioDiff) > 0 ? Number(row.count) > 0 ? formatNumber((parseFloat(Number(row.volRatioDiff)) / Number(row.count)), 'vol/diff') : 
            <span class="infinity-symbol">&#8734;</span> : 0,
            // sortable: true,
            style: { color: "#3a3a3a" }
        },
        // {
            // name: "CPS",
            // selector: (row) => formatNumber(Number(row.cps || 0), 'cps'),
            // sortable: true,
            // style: { color: "#3a3a3a" }
        // },
        {
            name: "",
            cell: (row) => <ActionCellSimple row={row} />
        },

    ], [isEditSubLabel])

    const handleEditSubLabel = (row) => {
        setSelectedRowId(row._id)
        setIsEditSubLabel(true);
    }

    const handleDeleteSubLabel = async (row) => {
        await labelService.deleteSubLabel({id: row._id, projectId: projectId})
        let search = JSON.parse(localStorage.getItem('search'))
        const labelSearchArray = search.labelSearch.split(','); // Split the string into an array using commas as separators
        const filteredLabelSearchArray = labelSearchArray.filter((item) => item !== label.labelName); // Remove label.labelName from the array
        search.labelSearch = filteredLabelSearchArray.join(','); 
        localStorage.setItem('search', JSON.stringify(search));
        keywordWithAPI(params.id, { ...search, limit, page, sort, sortBy });
    }

    const saveSublabel = async (data) => {
        const result = await labelService.editSubLabels({ ...data, projectId });
        if (result.status) {
            setIsEditSubLabel(false)
            toast.success(result.message, {
                autoClose: 1000,
                position: toast.POSITION.BOTTOM_LEFT
              });
            // dispatch(getLabels({id : params.id, searchParams :qs.stringify({search : search.current.value,label })}))
        } else {
            toast.error(result.message, {
                autoClose: 1000,
                position: toast.POSITION.BOTTOM_LEFT
              });
        }
    }

    const handleKeyDown = async (event) => {
        const inputValue = subLabels.current.value?.trim();
        if(event.key === 'Enter' && inputValue <=0){
            toast.warning("Sublabel can`t be empty", {
                autoClose: 1000,
                position: toast.POSITION.BOTTOM_LEFT
              });
        }
        if (event.key === 'Enter' && inputValue) {
            await labelService.createSubLabels({
                labels: inputValue,
                labelName: label.labelName,
                labelId: label._id,
                projectId
            });
            subLabels.current.value = "";
        }

    };


    const handleDelete = async () => {
        setShowModal(false)
        dispatch(deleteLabel(label._id)) 
        dispatch(deleteSelectedLabels(label._id)) 
        dispatch(handleDeleteLabels(label._id))
    }

    const handleSaveLabelName = async () => {
        if (!labelName.length) {
            toast.warning("Please add label name", {
                autoClose: 1000,
                position: toast.POSITION.BOTTOM_LEFT
              });
        } else {
            await labelService.updateLabels({ id: label._id, label: labelName })
            setIsEdit(false)
            toast.success("Successfully Updated", {
                autoClose: 1000,
                position: toast.POSITION.BOTTOM_LEFT
              });
        }
    }

    const handleCheckChange = (event) => {
        let labelSelected = selectedLabels.length ? [...selectedLabels] : [];
        let labelFilterSearch = search?.labelFilterSearch?.length ? search.labelFilterSearch.split(',') : [];
        
        if (event.target.checked) {
            labelSelected.push(label._id);
            dispatch(selectLabels(labelSelected));
            // labelFilterSearch.push(label.labelName);
        } else {
        if (labelSelected.includes(label._id)) {
            let newArr = labelSelected.filter(el => el !== label._id);
            // let newArr2 = labelFilterSearch.filter(el => el !== label.labelName);
            dispatch(selectLabels(newArr));
            // labelFilterSearch = newArr2;
        }
        }
        search.labelFilterSearch = labelFilterSearch.join(',');

        localStorage.setItem('search', JSON.stringify(search));
    }      

    return (
        <>
            <div className='item-label-name edit-box d-flex justify-content-between align-items-center'>
                {!isEdit ? (
                    <>
                        <div className="para-check">
                            <p className='title mb-0' style={{fontSize:"13px", fontWeight:"bold"}}>
                                {labelName}
                            </p>
                            <div className="form-check d-flex justify-content-center align-items-center" style={{ backgroundColor: "", cursor: "pointer" }}>
                                <input
                                    type="checkbox"
                                    checked={selectedLabels?.length ? selectedLabels.includes(label?._id): false}
                                    // value={selectedLabels?.length ? selectedLabels.includes(label?._id): false}
                                    onChange={handleCheckChange}
                                    name="subLabelSearchChecked"
                                    className="form-check-input"
                                    style={{ height: "18px", width: "18px", cursor: "pointer" }}
                                />
                                {/* <label className="form-check-label" /> */}
                            </div>
                        </div>
                    </>
                ) : (
                    <input className="border-radius-8 res-width" id='setSubLabelName' value={labelName} onChange={(e) => setLabelName(e.target.value)} />
                )}
                {!isEdit ? (
                    <div className='action-button border-radius-2'>
                        <button className='btn btn-outline-success p-1 border-radius-2' style={{borderRadius:"2px"}} onClick={() => setIsEdit(!isEdit)}> Edit </button>
                        <button className='btn btn-outline-danger p-1 border-radius-2' style={{borderRadius:"2px"}} onClick={() => setShowModal(true)} > Delete </button>
                    </div>

                ) : (
                    <div className='action-button'>
                        <button className='btn btn-outline-success p-1' onClick={handleSaveLabelName}> Save </button>
                        <button className='btn btn-outline-danger p-1' onClick={() => { setIsEdit(false); setLabelName(label.labelName) }} > Cancel </button>
                    </div>
                )}

            </div>
            <div className='lable-table'>
                <input type="text" className="label-name full-width border-radius-8" name="create-sub" ref={subLabels} onKeyDown={handleKeyDown} placeholder=' Add Labels' />
                <DataTable
                    columns={columns}
                    data={label?.subLabels}
                    className="left-table"
                    customStyles={{
                        headRow: {
                        style: {
                            color:'#9a9a9a'
                        },
                    },
                    }}
                />
            </div>
            <hr className='lable-HR' />
            {showModal && <LabelDeleteModal show={showModal} onClose = {() => setShowModal(false)} handleConfirm={handleDelete} title={"Delete Label"} text={`Are you sure you want to delete ?`} /> }
        </>

    )
}

export default LabelItem