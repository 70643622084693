import axios from 'axios'

const API_URL = '/api/gh2Pages/'

// Create new gh2Page
const createGh2Page = async (name) => {
  const response = await axios.post(API_URL, { name })

  return response.data
}

// Get getGh2Pages
const getGh2Pages = async () => {
  const response = await axios.get(API_URL)

  return response.data
}

// Get Single getGh2Pages
const getSingleGh2Page = async (id) => {

  const response = await axios.get(API_URL + id);
  return response.data;

}

// Update user goals
const updateGh2 = async (data) => {
  const response = await axios.put(API_URL, data)

  return response.data;
}

const updateGh2Data = async (data) => {
  const response = await axios.put(API_URL + `updateGh2Pages`, data)

  return response.data;
}

const deleteGh2 = async (id) => {
  const response = await axios.delete(API_URL + id);

  return response.data;
}

const deleteContent = async (id) => {
  const response = await axios.delete(API_URL + "content/" + id);

  return response.data;
}

const deleteOutput = async (id) => {
  const response = await axios.delete(API_URL + "output/" + id);

  return response.data;
}

const getMetrics = async (id) => {
  const response = await axios.get(API_URL + 'getMetrics/' + id);

  return response.data;
}

const getHistoricalSpeed = async () => {
  const response = await axios.get(API_URL + '/getHistorical/averageSpeed/');

  return response.data;
}

const generateSingleH2 = async (id, body) => {
  const response = await axios.post(API_URL + 'generateH2/' + id, body);

  return response.data;
}


const gh2sService = {
  createGh2Page,
  getGh2Pages,
  updateGh2,
  deleteGh2,
  getSingleGh2Page,
  generateSingleH2,
  updateGh2Data,
  deleteContent,
  getMetrics,
  deleteOutput,
  getHistoricalSpeed
}

export default gh2sService
