import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import fileService from "../../../features/file/fileService";
import DataTable from "react-data-table-component";
import { formatBytes, formatNumber } from "../../../utils/helper";

const FileLogModal = ({
  show,
  handleConfirm,
  selectedRow,
  text = "",
  onClose,
}) => {
  const [fileLogs, setFileLogs] = useState([]);
  const [alternateLogs, setAlternateLogs] = useState([]);

  async function getFileData() {
    try {
      const response = await fileService.getFileLogs(selectedRow?._id);
      if (response) {
        // console.log("Response: ", response.logs);
        if (response.logs.length > 0 && response.logs[0].metaData) {
          setFileLogs(response.logs[0].metaData);
        }
        if (
          (response.logs.length > 0 &&
            response.logs[0].metaData &&
            response.logs[0].metaData < 1) ||
          (response.logs.length > 0 && !response.logs[0].metaData)
        ) {
          let localNames = response.logs[0].localNames;
          let alternateFileLogs = localNames.map((localName) => {
            const fileName = localName.split("/").pop();
            // const timestampMatch = fileName.match(/data-keywords(\d+)\.csv/);
            // const timestamp = timestampMatch ? timestampMatch[1] : null;
            return {
              filename: fileName,
              timestamp: response.logs[0].updatedAt,
            };
          });
          // console.log("Alternatively: ", alternateFileLogs)
          setAlternateLogs(alternateFileLogs);
        }
      }
    } catch (error) {}
  }

  useEffect(() => {
    getFileData();
    return () => {};
  }, []);

  const columns = [
    {
      name: "File Name",
      selector: (row) => row.originalName || row.filename || "N/A",
      style: { fontSize: 11, color: "#3a3a3a", textAlign: "right" },
      width: "300px",
    },
    {
      name: "Size",
      selector: (row) => formatBytes(row.size || 10),
      style: { fontSize: 11, color: "#3a3a3a", textAlign: "right" },
    },
    {
      name: "Date Created",
      selector: (row) => {
        const date = row.timestamp ? new Date(row.timestamp) : new Date();
        const options = {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false, // Set to use 24-hour time format
        };
        return date.toLocaleString("en-US", options).replace(",", ""); // Remove comma after date
      },
      // width: "210px",
      style: { fontSize: 11, color: "#3a3a3a", textAlign: "right" },
    },
  ];

  return (
    <Modal className="logs-modal" show={show}>
      <Modal.Header closeButton onClick={() => onClose()}>
        <Modal.Title className="fs-5">Project: {selectedRow?.text}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ width: 650 }}>
        <div
          className="row pt-2"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {fileLogs.length > 0 || alternateLogs.length > 0 ? (
            <DataTable
              columns={columns}
              data={fileLogs.length > 0 ? fileLogs : alternateLogs}
              className="logs-table"
              fixedHeader
              style={{
                fontSize: 11,
                margin: "0 auto",
              }}
              // onChangePage={(page) => {
              //   setPage(page)
              // }
              // }
              // onChangeRowsPerPage={(limit) => {
              //   setPaginationSetting("projectList", limit)
              //   setLimit(limit)
              // }
              // }
              // onSort={sortFunction}
              // sortServer
              // paginationServer
              // paginationRowsPerPageOptions={paginationOptions}
              // paginationPerPage={limit || DEFAULT_LIMIT}
              // paginationTotalRows={totalCount}
              striped
              // paginationDefaultPage={page}
              // pagination
              customStyles={{
                expanderButton: {
                  style: {
                    color: "#ccc",
                  },
                },
                headRow: {
                  style: {
                    color: "#000",
                    backgroundColor: "#232323",
                    position: "sticky",
                  },
                },
                rows: {
                  style: {
                    color: "#000",
                    backgroundColor: "#fff",
                  },
                  stripedStyle: {
                    color: "#000",
                    backgroundColor: "#f4f4f4",
                  },
                },
                cells: {
                  style: {
                    textAlign: "left", // Align all cells to the left by default
                  },
                  rightAlign: {
                    // Custom style for right-aligned cells
                    textAlign: "right",
                  },
                },
              }}
              centered // Align columns to the center
            />
          ) : (
            <h6 className="fs-6">No record found</h6>
          )}
        </div>
        {/* {
        fileLogs && fileLogs.map(fileLog =>
          
          <>
        <div className="">
          File Name: {fileLog.filename} <br/>
          File Size: {fileLog.size}<br/>
          Timestamp: {fileLog.timestamp}<br/>
        </div>

        </>
          )
        } */}
      </Modal.Body>
      {/* <Modal.Footer>
       
    </Modal.Footer> */}
    </Modal>
  );
};

export default FileLogModal;
