import React from 'react'
import GH2PagesList from './GH2PagesLists'

const GeneratePages = () => {

    return (
        <GH2PagesList />
    )
}

export default GeneratePages