import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import tokenService from './tokenService'

const initialState = {
  tokens: [],
  isError: false,
  isSuccess: false,
  unblockedtokens: [],
  currentTokens: [],
  filterCurrentTokens: [],
  unmergedtokens: [],
  unblockCount: 0,
  filterBlockCount: 0,
  filterCount: 0,
  blockCount: 0,
  blockedtokens: [],
  isLoading: false,
  message: '',
}

// Get user tokens
export const getTokens = createAsyncThunk(
  'tokens/getUnblockTokens',
  async (data, thunkAPI) => {
    try {
      return await tokenService.getTokens(data)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


// Get user tokens
export const getBlockTokens = createAsyncThunk(
  'tokens/getBlockTokens',
  async (data, thunkAPI) => {
    try {
      return await tokenService.getBlockTokens(data)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const tokenSlice = createSlice({
  name: 'tokens',
  initialState,
  reducers: {
    resetTokens: (state) => initialState,
    getUnblockedTokens : (state, action) => {
      state.unblockedtokens = state.tokens.filter((el) => {
        if (!action.payload.token.length && !el.isBlocked) {
          return el
        } else if(action.payload.token.length && !el.isBlocked) {
          if(el.token === action.payload.token){
            return el;
          }
        }
      })
    },
    getBlockedTokens : (state, action) => {
      state.blockedtokens = state.tokens.filter((el) => {
        if (!action.payload.token.length && el.isBlocked) {
          return el
        } else if(action.payload.token.length && el.isBlocked) {
          if(el.token === action.payload.token){
            return el;
          }
        }
        })
    },
    setCurrentTokens : (state, action) => {
      state.currentTokens = action.payload
    },
    setFilterCurrentTokens : (state, action) => {
      state.filterCurrentTokens = action.payload
    },
    setunblockedTokens: (state, action) => {
      state.unblockedtokens = action.payload
    },
    
    setblockedTokens: (state, action) => {
      state.blockedtokens = action.payload
    },

    setunMergedTokens: (state, action) => {
      console.log(action.payload)
      if (Object.keys(action.payload).length < 1) {
        state.unmergedtokens = {}
      } else {
        state.unmergedtokens = { ...state.unmergedtokens, ...action.payload }
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getTokens.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getTokens.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.unblockedtokens =action.payload.tokens
        state.unblockCount = action.payload.count
        state.filterCount = action.payload.filtercount
      })
      .addCase(getTokens.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload.message
      })
      .addCase(getBlockTokens.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getBlockTokens.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.blockedtokens = action.payload.tokens
        state.blockCount = action.payload.count
        state.filterBlockCount = action.payload.filtercount

      })
      .addCase(getBlockTokens.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload.message
      })
  },
})

export const { resetTokens, getUnblockedTokens, getBlockedTokens, setunblockedTokens, setblockedTokens, setunMergedTokens, setCurrentTokens, setFilterCurrentTokens} = tokenSlice.actions
export default tokenSlice.reducer