import axios from "axios";

const API_URL = "/api/keywords/";

// Create new goal
const createKeyword = async (goalData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(API_URL, goalData, config);

  return response.data;
};

// Get user goals
const getKeywords = async (data, token) => {
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  const response = await axios.post(
    API_URL + data.projectId, {data: data.searchParams}
  );

  return response.data;
};

// Cleansing keyword
const cleansingKeyword = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL + "cleansing", body, config);
  return response.data;
};

const setGroupedDataAction = async (body) => {
  const response = await axios.post(API_URL + "setGroupedData", body);
  return response.data;
};
const updateGroupedDataAction = async (body) => {
  const response = await axios.post("/api/groups/update", body);

  return response.data;
};
const setUnGroupedDataAction = async (body) => {
  const response = await axios.post(API_URL + "setUnGroupedData", body);

  return response.data;
};

const groupedH2sData = async (body) => {
  const response = await axios.post(API_URL + "groupH2sData", body);

  return response.data;
};

const confirmingGroupH2sData = async (body) => {
  const response = await axios.post(API_URL + "confirmingGroupH2sData", body);

  return response.data;
};

const exportData = async (body) => {
  const response =  await axios.post(API_URL + 'exportCsv/file', body, {responseType: 'blob'});
  return response
};

const cleanseKeywords = async (id) => {
  const response =  await axios.post(API_URL + 'cleansing/Keywords', {projectId: id});
  return response
};

const keywordService = {
  createKeyword,
  getKeywords,
  cleansingKeyword,
  setGroupedDataAction,
  setUnGroupedDataAction,
  updateGroupedDataAction,
  groupedH2sData,
  confirmingGroupH2sData,
  exportData,
  cleanseKeywords
};

export default keywordService;
