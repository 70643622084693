import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import goalService from "../../../features/project/projectService";
import { getProjects } from '../../../features/project/projectSlice'
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux'

const DeleteConfirmation = ({ showModal, onClose, id, name, search, page, limit, sortedObj}) => {
    const [projectName, setProjectName] = useState("");
    const [error, setError] = useState("");
    const [isdisable, setDisable] = useState(false);
    const dispatch = useDispatch()

    const handleConfirm = () => {
        if (projectName.trim() === "") {
            setError("Please enter the project name.");
            console.log(error)
        } else {
            setError("");
            onClose();
            confirmModal();
        }
    };

    const confirmModal = async() => {
        const response = await goalService.deleteProject({id:id, name:projectName})
        if(response.status){
            const RP_ID = localStorage.getItem('RP_ID')
            if(RP_ID?.length && (RP_ID == id)) { localStorage.removeItem('RP_ID')}
            toast.success("Project Deleted Successfully", {
                autoClose: 1000,
                position: toast.POSITION.BOTTOM_LEFT
              });
            dispatch(getProjects({ name: search, page: page, limit: limit, sortedObj }))  
        }else{
            toast.error("No Match Found.", {
                autoClose: 1000,
                position: toast.POSITION.BOTTOM_LEFT
              });
        }
    };

    const ProjectName = async(value) => {
        setDisable(false)
        if(value.toLowerCase() == name.toLowerCase()){
            setDisable(true)
        }
        setProjectName(value)
    }

    return (
        <Modal show={showModal}>
        <Modal.Header closeButton onClick={() => onClose()}>
            <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="alert alert-danger">
            Are you sure you want to delete the project?
            </div>
            <Form.Group>
            <Form.Label>Project Name:</Form.Label>
            <Form.Control
                type="text"
                value={projectName}
                onChange={(e) => ProjectName(e.target.value)}
            />
            {/* {error && <div className="pt-1 text-danger">{error}</div>} */}
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="default" closeButton onClick={() => onClose()}>
            Cancel
            </Button>
            <Button variant="danger" disabled={!isdisable} onClick={() => {
                handleConfirm();
            }}>
            Delete
            </Button>
        </Modal.Footer>
        </Modal>
    );
};

export default DeleteConfirmation;