import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import KwGrouping from "../components/kw-grouping/KwGrouping";
import { getSocket } from "../socket";
const socket = getSocket()

const Keywords = () => {
  return (
    <div className="keyword-screen container-fluid custom-padding">
      <div className="row">
        <div className="col-md-12">
          <Tabs
            defaultActiveKey={0}
            id="fill-tab-example"
            className="mb-3 projects-grouping-tab"
            fill
          >
            <Tab eventKey={0} title="Group">
              <KwGrouping socket={socket} />
            </Tab>
            <Tab eventKey={1} title="Content"></Tab>
            <Tab eventKey={2} title="Publish"></Tab>
            {/* <Tab eventKey={3} title="Content(H2s)"></Tab>
            <Tab eventKey={4} title="Content(H1s)"></Tab>
            <Tab eventKey={5} title="Content(Metas)"></Tab> */}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Keywords;
