import axios from 'axios'

const API_URL = '/api/labels/'

// Create new goal
const createLabel = async (labels, projectId) => {
  const response = await axios.post(API_URL, {labels, projectId})

  return response.data
}

const createSubLabels = async (data) => {
    const response = await axios.post(API_URL + 'subLabels', data)
  
    return response.data
  }
  
// Get user goals
const getLabels = async (data) => {
  const response = await axios.get(API_URL + data.id +  `?${data.searchParams}`)

  return response.data
}

// Update user goals
const updateLabels = async (data) => {
  const response = await axios.put(API_URL, data)

  return response.data
}

const deleteLabel = async (id) => {
  const response = await axios.delete(API_URL + id);

  return response.data
}

const deleteSubLabel = async (data) => {
  const response = await axios.delete(API_URL + 'subLabels/' + data.id + '/' + data.projectId)
  return response.data
}

const editSubLabels = async (data) => {
  const response = await axios.put(API_URL + "subLabels" , data);
  return response.data

}

const labelService = {
createLabel,
getLabels,
deleteSubLabel,
updateLabels,
createSubLabels,
deleteLabel,
editSubLabels,
}

export default labelService
