// src/EditorComponent.js. 

import React, { useState } from 'react';
import { EditorState, ContentState, convertToRaw } from 'draft-js';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { truncateString } from '../../../utils/helper';
import uploadService from '../../../features/upload/uploadService';
import draftToHtml from 'draftjs-to-html';
import { convertFromHTML } from 'draft-js';


// Customize the toolbar options
const toolbarOptions = {
  options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'history'],
  inline: {
    options: ['bold', 'italic', 'underline'],
  },
  blockType: {
    options: ['Normal', 'H1', 'H2', 'H3', 'Blockquote'],
  },
  list: {
    options: ['unordered', 'ordered'],
  },
  textAlign: {
    options: ['left', 'center', 'right'],
  },
};

const EditorComponent = ({ row }) => {

  const initialContentState = ContentState.createFromText(row.h2Input);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(initialContentState));
  const [isEdit, setIsEdit] = useState(false);

  const handleSave = async () => {

    setIsEdit(false);

    const htmlInputContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    await uploadService.updategh2s({ ...row, h2Input: htmlInputContent })
  };

  const handleLoad = () => {
    const blocksFromHTML = convertFromHTML(row.h2Input); // Parse HTML content
    const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    const newEditorState = EditorState.createWithContent(contentState);

    setEditorState(newEditorState);
    setIsEdit(true); // Set editor to edit mode
  };

  const convertHtmlToPlainText = (html) => {
    const tempElement = document.createElement('span');
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || '';
  }

  return (
    <div>
      {
        isEdit ?
          <>
            <Editor
              editorState={editorState}
              onEditorStateChange={setEditorState}
              toolbar={toolbarOptions}
            />
            <button className='btn btn-outline-dark no-radius green-bg' onClick={handleSave}> Save </button>
            {/* <button className='btn btn-outline-dark no-radius bg-red ms-2' onClick={() => setIsEdit(false) }> Cancel </button> */}
          </>
          :
          <div onDoubleClick={handleLoad}>
            {truncateString(convertHtmlToPlainText(row.h2Input), 100)}
          </div>
      }
    </div>
  );
};

export default EditorComponent;