import React, { useEffect, useState, useRef, useMemo, useCallback, Suspense, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Highlighter from "react-highlight-words";
import DataTable from "react-data-table-component";
import Select from 'react-select';
import { paginationOptions } from "../../utils/constants";
import {
  reset,
  setGroupedDataAction,
  cleanseKeyword,
  clearRowsData
} from "../../features/keyword/keywordSlice";
import { toast } from "react-toastify";
import { resetUpload } from "../../features/upload/uploadSlice";
import { Row, Tab, Tabs, Col, Button } from "react-bootstrap";
import {
  setUnGroupedDataAction,
  resetGroup,
  setStateGroups,
} from "../../features/groups/groupSlice";
import { getBlockTokens, getTokens, setCurrentTokens } from "../../features/tokens/tokenSlice";
import Ngrams from "./ngrams/Ngrams";
import goalService from "../../features/keyword/keywordService";
import countService from "../../features/count/countService";
import qs from "qs";
import { ExportSection, FileUpload } from "./components/index";

import { getSocket } from "../../socket";
import FilterSection from "../shared/components/FilterSection";
import groupService from "../../features/groups/groupService";
import h2sOrderService from "../../features/h2sOrder/h2sOrderService";
// import { OrderH2, UnGroup, Group, UnGroupH2, GroupH2 } from "./Tabs/index"
import { DEFAULT_LIMIT, DEFAULT_LIMIT_UGKWS, DEFAULT_PAGINATION_SETTING } from "../../utils/constants";
import Labels from "./labels/Labels";
import { deleteLabels, selectLabels } from "../../features/labels/labelSlice";
import debounce from 'lodash/debounce';
import tokenService from "../../features/tokens/tokenService";
// import CustomPagination from "../shared/components/CustomPaginationComponent";
const socket = getSocket()
// Lazy load the components for each tab
const UnGroup = lazy(() => import('./Tabs/UnGroup'));
const Group = lazy(() => import('./Tabs/Group'));
const UnGroupH2 = lazy(() => import('./Tabs/UnGroupH2'));
const GroupH2 = lazy(() => import('./Tabs/GroupH2'));
const OrderH2 = lazy(() => import('./Tabs/OrderH2'));

let timeoutId;
const CustomCell = React.memo(({ row, search, handleSingleBlock, handleSearch }) => {
  const handleNgramSelect = (e, el) => {
    if (e.ctrlKey || e.metaKey) {
      handleSingleBlock({ token: el });
      return;
    }
  }

  return (
    <Row key={row} className="w-100">
      <Col lg={4} className="d-flex align-items-center">
        <Highlighter
          highlightClassName=""
          className="text-left"
          searchWords={[
            ...search.include.split(","),
            ...search.filter.split(","),
          ]}
          autoEscape={true}
          textToHighlight={row.keyword}
        />
      </Col>
      <Col lg={8} xxl={6} className="p-0">
        <div className="filter-components">
          {row?.tokens?.split(' ').map((el) => (
            <span title="Search" onClick={(e) => handleNgramSelect(e, el)}>
              {el}
            </span>
          ))}
        </div>
      </Col>
    </Row>
  );
});

const KwGrouping = () => {
  const {
    message,
    isCleansingError,
    isCleansingSucces,
    isSuccess,
    isLoading,
    selectedOrderH2Rows,
    setSelectedUngroupRows,
    setSelectedUnGroupH2Rows,
    setSelectedGroupH2Rows,
    setSelectedGroupRows
  } = useSelector((state) => state.keywords);

  const { isSuccessGroup, groupMessage } = useSelector((state) => state.groups);
  const { unblockedtokens } = useSelector((state) => state.tokens);
  const [keywords, setKeywords] = useState([]);
  // const [unGroupCount, setUnGroupCount] = useState([]);
  const [blocked, setBlocked] = useState([]);
  const [search, setSearch] = useState({
    include: "",
    exclude: "",
    filter: "",
    labelSearch: "",
    excludeLabelSearch: "",
    labelFilterSearch: "",
    includeSelect: "All",
    excludeSelect: "All",
    page: 1,
    limit: JSON.parse(localStorage.getItem('paginationSetting'))?.ungroup.limit || DEFAULT_LIMIT_UGKWS,
  });
  const [clearSelectedRows, setClearSelectedRows] = useState(false)
  const { isSuccessUpload, isErrorUpload, isUploading } = useSelector(
    (state) => state.files
  );

  const [dataUgh2, setUgh2Data] = useState([])
  const [dataGroupH2, setGroupH2Data] = useState([])
  const [h2sOrderData, setH2sOrderData] = useState([])
  const dispatch = useDispatch();
  const params = useParams();
  const [selectedTab, setSelectedTab] = useState("0");
  // const [groupedRowsGroup, setGroupedRowsGroup] = useState([]);
  const { groups, groupFilterCount } = useSelector((state) => state.groups);
  const { selectedLabels, labels } = useSelector((state) => state.labels)

  const [sort, setSort] = useState();
  const [sortBy, setSortBy] = useState();
  // const [USort, setUngroupSort] = useState();
  // const [USortBy, setUngroupSortBy] = useState();

  const [limit, setLimit] = useState(JSON.parse(localStorage.getItem('paginationSetting'))?.group?.limit || DEFAULT_LIMIT);
  // const [page, setPage] = useState(1)

  const [blimit, setBLimit] = useState(JSON.parse(localStorage.getItem('paginationSetting'))?.blocked?.limit || DEFAULT_LIMIT);
  const [bpage, setBPage] = useState(1)

  const [groupsTotal, setGroupsTotal] = useState({ count: 0, pageCount: 0 });
  // const [orderH2sTotal, setOrderH2sTotal] = useState({ count: 0, pageCount: 0 });
  // const [ugH2sTotal, setUGH2sTotal] = useState({ count: 0, pageCount: 0 });
  // const [groupedH2sTotal, setGroupedGH2sTotal] = useState({ count: 0, pageCount: 0 });

  const [unblockedFilter, setBlockedFilterTotal] = useState(0);
  const [ugH2sTotalFilter, setugH2sTotalFilterTotal] = useState(0)
  const [orderH2sTotalFilter, setorderH2sTotalFilterTotal] = useState(0)
  const [filterCount, setFilterCount] = useState({ ugkwCount: 0, groupsCount: 0, ugh2sCount: 0 })
  const [checkedLabels, setCheckedLabels] = useState(false)
  const [checkunLabels, setCheckedUnLabels] = useState(false)
  const [action] = useState({ fromUser: false }); //this is a way to have an instant-changing state
  const isFirstRender = useRef(true);
  const [labelSearch, setlabelSearch] = useState("");
  const [labelPage, setlabelPage] = useState("")
  const [labelLimit, setlabelLimit] = useState("");
  const [groupNames, setGroupNames] = useState([]);
  const [newGroupNames, setNewGroupNames] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to track if the menu is open
  const [counts, setCounts] = useState();
  const [sortedObj, setSortedObj] = useState({});
  const [isTabChanging, setIsTabChanging] = useState(false);
  // const [uploadStatus, setUploadStatus] = useState(false);

  // Ug (H2s) Page Limit 
  // const [ugh2sLimit, setUgh2sL] = useState((JSON.parse(localStorage.getItem('paginationSetting'))?.ugh2s?.limit))
  // const [ugh2sPage, setUgh2sPage] = useState(1)

  // G (H2s) Page Limit 
  const [gh2sFilterCount, setGh2sFilterCount] = useState((JSON.parse(localStorage.getItem('paginationSetting'))?.gh2s?.limit))
  // const [gh2sPage, setGh2sPage] = useState(1)


  const [triggerEffect, setTriggerEffect] = useState(false);

  const columns = useMemo(() => [
    {
      name: "Keyword",
      selector: (row) => row.keyword,
      sortable: true,
      width: "65%",
      style: { fontSize: 13 },
      cell: (row) => (
        <CustomCell
          row={row}
          search={search}
          setSearch={setSearch}
          handleSingleBlock={handleSingleBlock}
          handleSearch={handleSearch}
        />
      ),
    },
    {
      name: "Vol",
      selector: (row) => Number(row.volume),
      sortable: true,
      width: "10%",
      style: { fontSize: 13, color: "#3a3a3a" }
    },
    {
      name: "Diff",
      selector: (row) => Number(row.difficulty),
      sortable: true,
      width: "10%",
      style: { fontSize: 13, color: "#3a3a3a" }

    },
    {
      name: "Vol/Diff",
      selector: (row) => (isNaN(row.volumeRatioDiff) || row.volumeRatioDiff == null) ? Math.round((Number(row.volume) / Number(row.difficulty))) : Math.round(Number(row.volumeRatioDiff)),
      sortable: true,
      width: "10%",
      style: { fontSize: 13, color: "#3a3a3a" }
    },
  ], [triggerEffect])

  const selectedName = useMemo(() => params.name.replaceAll("-", " "), [params])


  const sortFunction = (selector, direction) => {
    let sortBy = selector.name === 'Vol/Diff' ? 'volumeRatioDiff' : selector.name;
    if (selectedTab === "5") {
      socket.emit("UNCLEANSED_KEYWORD", {
        id: params.id,
        query: { ...search, page: bpage, limit: blimit, sort: direction, sortBy },
      });
    }

    setSort(direction);
    setSortBy(sortBy);
  }

  const label = useRef();
  const groupName = useRef();

  // const {label, groupName} = useMemo( () => {

  // }, [])

  const getSortData = (e) => {
    setSort(e.sort);
    setSortBy(e.sortBy);
  }

  async function keywordWithAPI(id, search) {

    const words = await goalService.getKeywords({
      projectId: id,
      searchParams: search
    });
    setKeywords(words.keywords);
    manipulateTokensAgainstKeywords(words.keywords)
  }

  useEffect(async() => {
    try {
      const words = await goalService.getKeywords({
        projectId: params.id,
        searchParams: search
      });
      setKeywords(words.keywords);
    } catch (error) {
      
    }
    return () => {
      
    }
  }, [triggerEffect])
  

  //Fetching ungroup H2s data
  async function getUGH2sData(search) {
    const response = await groupService.getUgH2s({
      id: params.id,
      query: qs.stringify({
        ...search,
      })
    });
    setUgh2Data(response.groups);
    setugH2sTotalFilterTotal(response.count);
    manipulateTokensAgainstKeywords(response.groups, 'ugh2')
  }

  //Fetching Existing Group Names
  async function fetchExistingGroupNames() {
    const response = await groupService.getGroupsNames({
      id: params.id,
    })
    setGroupNames(response)
  }

  //Fetching Grouped H2s data
  async function getGroupH2sData(search) {
    const response = await groupService.getGroupH2s({
      id: params.id,
      query: qs.stringify({
        ...search
      })
    });
    setGroupH2Data(response.groups);
    setGh2sFilterCount(response.filterCount)
    manipulateTokensAgainstKeywords(response.groups)
  }

  //Fetching H2s Order Data
  async function getH2sOrdersData(search) {
    const response = await h2sOrderService.getH2sOrders({
      id: params.id,
      query: qs.stringify({
        ...search,
      })
    });

    setH2sOrderData(response.h2Orders);
    setorderH2sTotalFilterTotal(response.count)
  }



  useEffect(() => {
    getCount()
    fetchExistingGroupNames()
    localStorage.setItem('RP_ID', params.id)
    socket.on("RESULT_UNCLEANSED" + params.id, (data) => {
      setBlocked(data.uncleansedkeywords)
      // setCleansedTotal(data.count);
      setBlockedFilterTotal(data.filterCount)
    });
    socket.on(`UNGROUP_CALLED` + params.id, () => {
      getCount()
    });
    socket.on(`GROUP_CREATED` + params.id, (data) => {
      getCount()
    });
    socket.on(`UPDATE_DATA` + params.id, (data) => {
      getCount()
    });

  }, [triggerEffect])

  const manipulateTokensAgainstKeywords = async (results, type) => {
    if (!results.length) {
      return [];
    }

    const words = [];
    const tokensMap = {};
    let uniqueObjects = results;

    if (type !== 'ugh2') {
      uniqueObjects = results.flatMap((el) => el.keywords);
    }

    uniqueObjects.forEach((el) => {
      if (el.tokens) {
        words.push(...el.tokens.split(" "));
      }
    });
    if (!words.length) {
      return [];
    }

    try {
      const countOfWords = await getWordCntRd(words);
      const tokens = getTokens(countOfWords, uniqueObjects);

      if (tokens.length) {
        tokens.forEach((el) => {
          tokensMap[el.token] = el;
        });


        const result = await tokenService.getTokens({
          id: params.id,
          query: {
            sortedObj: {},
            mergedTokens: 'true',
            tokens: [...new Set(words)].join(','),
            limit: [...new Set(words)].length,
            page: 1,
          },
        });

        const hl = result.tokens
          .map((elem) => {
            const doc = tokensMap[elem.token];
            if (doc !== undefined) {
              doc.tokens = [];
              if (elem.tokens.length) {
                doc.tokens = elem.tokens
                  .map((el) => {
                    if (tokensMap[el.token]) {
                      return { ...tokensMap[el.token], _id: el._id, volume: el.volume, orignalParentToken: el.orignalParentToken, parentToken: el.parentToken, orignalParentTokenId: el.orignalParentTokenId, parentTokenId: el.parentTokenId };
                    }
                  })
                  .filter(Boolean);
              }
              if (!doc.tokens.length) {
                return
              }
              return { ...doc, _id: elem._id, volume: elem.volume };
            }
          })
          .filter(Boolean);

        dispatch(setCurrentTokens(hl));
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getWordCntRd = (array) => {
    return array.reduce((wordCounts, word) => {
      if (wordCounts.hasOwnProperty(word)) {
        wordCounts[word]++;
      } else {
        wordCounts[word] = 1;
      }
      return wordCounts;
    }, {});
  };
  const getTokens = (countOfWords, uniqueObjects) => {
    try {
      const uniqueKeywords = {};
      const keywordVolumeMap = {};

      if (!uniqueObjects || !Array.isArray(uniqueObjects)) {
        return []; // Return an empty array or handle this case appropriately.
      }

      // Preprocess uniqueObjects to extract keywords and volumes
      for (const item of uniqueObjects) {
        if (item.tokens) {
          const keywords = item.tokens.split(" ");
          for (const keyword of keywords) {
            if (!uniqueKeywords[keyword]) {
              uniqueKeywords[keyword] = true;
            }
            const volume = +item.volume || 0;
            if (keywordVolumeMap[keyword]) {
              keywordVolumeMap[keyword].volume += volume;
            } else {
              keywordVolumeMap[keyword] = { volume };
            }
          }
        }
      }

      const tokens = [];
      for (const key in countOfWords) {
        if (uniqueKeywords[key]) {
          const keywordData = keywordVolumeMap[key];
          const volume = keywordData ? keywordData.volume : 0;

          tokens.push({
            token: key,
            count: countOfWords[key],
            currentVolume: volume,
          });
        }
      }

      return tokens;
    } catch (error) {
      console.log(error, "While Running getTokens");
      return [];
    }
  };

  useEffect(() => {
    localStorage.setItem('search', JSON.stringify(search))
    localStorage.setItem('selectedTab', 0)
    let paginationSetting = JSON.parse(localStorage.getItem('paginationSetting'))
    if (!paginationSetting) {
      localStorage.setItem('paginationSetting', JSON.stringify(DEFAULT_PAGINATION_SETTING));
    }
    else {
      setSearch((prevState) => ({
        ...prevState,
        limit: paginationSetting.ungroup.limit,
      }));
      setLimit(paginationSetting.group.limit)
      setBLimit(paginationSetting.blocked.limit)
      console.log(limit)
      // setGh2sLimit(paginationSetting.gh2s?.limit || DEFAULT_LIMIT)
      // setUgh2sLimit(paginationSetting.ugh2s?.limit || DEFAULT_LIMIT)
    }
    // getUGH2sData(search);
    // getGroupH2sData(search)
    // getCSVDatafOrderH2s();
    // setProjectName(params.name.replaceAll("-", " "));
    return () => {
      socket.off(`SEARCH_RESULT`);
      socket.off(`FILTER`);
      socket.off(`UNGROUP_CALLED`);
      socket.off(`UNCLEANSED_KEYWORD`);
      socket.off(`RESULT_UNCLEANSED`);
      socket.off("GROUP_CREATED");
      dispatch(reset());
      dispatch(resetGroup());
      dispatch(resetUpload())
    };
  }, [triggerEffect]);

  useEffect(() => {
    dispatch(clearRowsData())
    setSelectedOption('')
  }, [selectedTab])

  useEffect(() => {
    let selectedRows1 = getAllSelectedRows(setSelectedUngroupRows);
    let selectedRows2 = getAllSelectedRows(setSelectedUnGroupH2Rows);
    let selectedRows3 = getAllSelectedRows(setSelectedGroupRows);

    if (selectedRows1?.length) {
      const maxVolumeObject = keywords.reduce((maxObject, currentObject) => {
        const currentObjectData = setSelectedUngroupRows[currentObject.ngrams];
        if (currentObjectData && currentObject.totalVolume > maxObject.totalVolume) {
          return currentObject;
        } else {
          return maxObject;
        }
      }, { ngrams: "", totalVolume: -Infinity });
      setSelectedOption({ label: maxVolumeObject._id, value: maxVolumeObject._id })
      // const isIdInGroupNames = groupNames.some(item => item.value === maxVolumeObject._id);
      // if (!isIdInGroupNames) {
      //   // Push a new object into the groupNames array
      //   setGroupNames(prevGroupNames => [...prevGroupNames, { label: maxVolumeObject._id, value: maxVolumeObject._id }]);
      // }
    } else if (selectedRows2.length && selectedTab === '2') {
      let str = selectedRows2.reduce((prev, current) =>
        +prev.volume > +current.volume ? prev : current
      ).keyword;
      groupName.current.value = str;
    } else if (selectedRows3.length > 0 && selectedTab === '1') {

      const maxVolumeObject = groups.reduce((maxObject, currentObject) => {
        const currentObjectData = setSelectedGroupRows[currentObject.name];
        if (currentObjectData && currentObject.totalVolume > maxObject.totalVolume) {
          return currentObject;
        } else {
          return maxObject;
        }
      }, { name: "", totalVolume: -Infinity, label: "" })
      setSelectedOption({ label: maxVolumeObject.name, value: maxVolumeObject.name })
      // const isIdInGroupNames = groupNames.some(item => item.value === maxVolumeObject._id);
      // if (!isIdInGroupNames) {
      //   // Push a new object into the groupNames array
      //   setGroupNames(prevGroupNames => [...prevGroupNames, { label: maxVolumeObject._id, value: maxVolumeObject._id }]);
      // }
      label.current.value = maxVolumeObject.label;
    }
    else {
      if (groupName && groupName.current) {
        groupName.current.value = "";
      }
      label.current.value = "";
      setSelectedOption('')
    }

  }, [setSelectedUngroupRows, setSelectedUnGroupH2Rows, setSelectedGroupRows, selectedTab]);

  useEffect(() => {
    let selectedRows1 = getAllSelectedRows(setSelectedGroupH2Rows);
    if (selectedRows1.length > 0 && selectedTab === '3') {
      let checkIfGroupSelected = dataGroupH2.some(el => {
        let haveData = setSelectedGroupH2Rows[el._id]
        if (haveData && haveData.length) {
          return haveData.length === el.keywords.length;
        } else {
          return false
        }
      })
      if (checkIfGroupSelected) {
        const maxVolumeObject = dataGroupH2.reduce((maxObject, currentObject) => {
          const currentObjectData = setSelectedGroupH2Rows[currentObject._id];
          if (currentObjectData && currentObject.totalVolume > maxObject.totalVolume) {
            return currentObject;
          } else {
            return maxObject;
          }
        }, { name: "", totalVolume: -Infinity, label: "" })
        groupName.current.value = maxVolumeObject._id;
      } else {
        let str = selectedRows1.reduce((prev, current) =>
          +prev.volume > +current.volume ? prev : current
        ).keyword;
        groupName.current.value = str;
      }
    } else {
      if (groupName && groupName.current) {
        groupName.current.value = "";
      }
    }
  }, [setSelectedGroupH2Rows, selectedTab]);

  useEffect(() => {
    if (isCleansingError) {
      toast.error("No keywords found ", {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
      dispatch(
        getTokens({
          id: params.id,
          query: { tokens: "", limit: DEFAULT_LIMIT, page: 1 },
        })
      );
      dispatch(
        getBlockTokens({
          id: params.id,
          query: { tokens: "", limit: DEFAULT_LIMIT, page: 1 },
        })
      );
    }

    if (isCleansingSucces) {
      toast.success(message, {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
      socket.emit("UNCLEANSED_KEYWORD", {
        id: params.id,
        query: { ...search, limit: blimit, page: bpage },
      });
    }
  }, [isCleansingError, isCleansingSucces]);


  useEffect(() => {
    if (isSuccessUpload) {
      // setUploadStatus(true);
      toast.success("Successfully uploaded keywords", {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
      keywordWithAPI(params.id, search);
      getCount()
      // dispatch(
      //   getTokens({
      //     id: params.id,
      //     query: { tokens: "" , limit:DEFAULT_LIMIT, page:1 },
      //   })
      // );
      dispatch(
        getBlockTokens({
          id: params.id,
          query: { tokens: "", limit: DEFAULT_LIMIT, page: 1 },
        })
      );
      dispatch(resetUpload())
    }

    if (isErrorUpload) {
      toast.error("Error uploading the file", {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
    }

    if (isSuccess) {
      toast.success(message, {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  }, [isSuccessUpload, isErrorUpload, isSuccess]);

  useEffect(() => {
    if (isSuccessGroup) {
      toast.success(groupMessage, {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  }, [isSuccessGroup]);

  useEffect(() => {
    if (selectedTab === "5") {
      socket.emit("UNCLEANSED_KEYWORD", {
        id: params.id,
        query: { ...search, page: bpage, limit: blimit, sort, sortBy },
      });
    }
  }, [selectedTab]);

  useEffect(() => {
    if (selectedTab == '5') {
      socket.emit("UNCLEANSED_KEYWORD", {
        id: params.id,
        query: { ...search, page: bpage, limit: blimit, sort, sortBy }
      });
    }

    if (checkedLabels) {
      let selectedIds = [];
      labels.forEach(label => {
        selectedIds.push(label._id);
      })
      dispatch(selectLabels(selectedIds))
    } else {
      dispatch(deleteLabels([]))
    }

    search.checkedLabels = checkedLabels
    localStorage.setItem('search', JSON.stringify(search));

  }, [blimit, bpage, checkedLabels])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return; // Skip running the effect on the first render
    }
    if (checkunLabels && action.fromUser) {
      let wordsToAdd = [];
      labels.forEach(label => {
        wordsToAdd.push(...label?.subLabels.map((el) => el.name));
      })

      search.excludeLabelSearch = wordsToAdd.join(',');
    } else {
      search.excludeLabelSearch = "";
    }
    keywordWithAPI(params.id, { ...search, unlabelCheck: checkunLabels, checkedLabels, sort, sortBy });

    search.unlabelCheck = checkunLabels;
    localStorage.setItem('search', JSON.stringify(search));
  }, [checkunLabels])

  const handleSearch = (e) => {
    let search = JSON.parse(localStorage.getItem('search'))
    clearTimeout(timeoutId);
    const { name, value } = e.target;

    const updatedSearch = {
      ...search,
      checkedLabels: checkedLabels,
      unlabelCheck: checkunLabels,
      [name]: value,
    };

    localStorage.setItem('search', JSON.stringify(updatedSearch));
    let searching = JSON.parse(localStorage.getItem('search'))
    timeoutId = setTimeout(async () => {
      if (name === 'limit') {
        setPaginationSetting("ungroup", value)
      }

      setSearch(searching);

      const query = {
        ...searching,
        sort,
        sortBy,
      }
      if (selectedTab === "5") {
        socket.emit("UNCLEANSED_KEYWORD", {
          id: params.id, query: {
            ...query,
            limit: blimit,
            page: bpage
          }
        });
      }
    }, 150);
  };

  useEffect(() => {
    // console.log(search, "This is the saatch ");
    // if (search.filter.length || search.include.length || search.exclude.length || search.labelSearch.length) {
      console.log("hellllooo I am called");

      getFilterCountApi(search);
    // } else {
    //   setFilterCount({ ugkwCount: 0, groupsCount: 0, ugh2sCount: 0 })
    // }
  }, [selectedTab,search.filter,search, search.labelSearch, search.include, search.exclude, search.excludeLabelSearch,])

  useEffect(() => {
    manipulateTokensAgainstKeywords(groups)
  }, [groups])


  const setUnGroupedDataCAll = () => {
    const selectedData = getAllSelectedRows(setSelectedGroupRows)
    if (!selectedData?.length) {
      toast.error("Kindly select any keyword", {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
      return;
    }
    updateInGkws()
    dispatch(
      setUnGroupedDataAction({
        result: selectedData,
        projectId: params.id,
        filterFor: "groupAndUngroup"
      })
    );
    setClearSelectedRows(!clearSelectedRows)
    setSelectedOption('')
    dispatch(clearRowsData())
    // setGroupedRows([]);
  };

  const updateInGkws = () => {
    const keys = Object.keys(setSelectedGroupRows);

    const newArr = groups.map(
      (el) => {
        if (keys.includes(el.name)) {
          let keywords = el.keywords.filter(kw => !setSelectedGroupRows[el.name]?.some(el => el._id === kw._id))
          if (keywords.length > 0) {
            return { ...el, keywords }
          } else {
            return
          }
        } else {
          return el;
        }
      }
    ).filter(Boolean)
    dispatch(setStateGroups(newArr))
  }

  const updateInGh2s = () => {
    const keys = Object.keys(setSelectedGroupH2Rows);

    const newArr = dataGroupH2.map(
      (el) => {
        if (keys.includes(el._id)) {
          let keywords = el.keywords.filter(kw => !setSelectedGroupH2Rows[el._id]?.some(el => el._id === kw._id))
          if (keywords.length > 0) {
            return { ...el, keywords }
          } else {
            return
          }
        } else {
          return el;
        }
      }
    ).filter(Boolean)
    setGroupH2Data(newArr)
  }

  const effiecientGrouping = async () => {
    const selectedData = getAllSelectedRows(setSelectedGroupRows)
    if (!selectedData?.length) {
      toast.error("Kindly select any keyword", {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
      return;
    }
    if (!selectedOption?.value) {
      toast.error("Page name can't be empty", {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
      return;
    }
    await groupService.effiecientGrouping({
      result: selectedData,
      projectId: params.id,
      name: selectedOption.value,
      label: label.current.value

    })
    toast.success("Successfully updated the keywords", {
      autoClose: 1000,
      position: toast.POSITION.BOTTOM_LEFT
    });
    setClearSelectedRows(!clearSelectedRows)
    dispatch(clearRowsData())
  }

  const effiecientGroupingForGh2s = async () => {
    const selectedData = getAllSelectedRows(setSelectedGroupH2Rows)
    if (!selectedData?.length) {
      toast.error("Kindly select any keyword", {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
      return;
    }
    if (!groupName.current?.value) {
      toast.error("Page name can't be empty", {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
      return;
    }
    await groupService.effiecientGroupingForGh2s({
      result: selectedData,
      projectId: params.id,
      name: groupName.current?.value,
      label: label.current.value
    })

    toast.success("Successfully updated the keywords", {
      autoClose: 1000,
      position: toast.POSITION.BOTTOM_LEFT
    });
    setClearSelectedRows(!clearSelectedRows)
    dispatch(clearRowsData())
  }


  const handleKeyPress = (e) => {
    if ((e.ctrlKey && e.which === 13) || (e.ctrlKey && e.which === 13)) {
      setGroupedData();
    }

    if(e.shiftKey && selectedTab == 0) {
      setGroupedData();
    }

    if (e.shiftKey  && selectedTab == 1) {
      setUnGroupedH2sData()
    }

  };

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);


  const handleMouseDown = (event) => {
    if (event.button === 1 && selectedTab == 0) {
      // Middle mouse click action
      setGroupedData();
      // Add your custom action code here
    }
    if (event.button === 1 && selectedTab == 1) {
      // Middle mouse click action
      setUnGroupedH2sData()
      // Add your custom action code here
    }
  }


  useEffect(() => {
    // attach the event listener
    document.addEventListener("mousedown", handleMouseDown);

    // remove the event listener
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, [handleMouseDown]);



  const handleSingleBlock = useCallback((el) => {
    dispatch(cleanseKeyword({ word: el.token, projectId: params.id, tokens: [el], cleansing: true, searchTokenByName: true }));
  }, [unblockedtokens]);



  const setGroupedData = async () => {
    const selectedData = getAllSelectedRows(setSelectedUngroupRows);
    if (!selectedData || selectedData.length === 0) {
      toast.error("Kindly select any keyword", {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
      return;
    }
    if (!selectedOption?.value) {
      toast.error("Page name can't be empty", {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
      return;
    }

    await updateAfterGrouping();

    dispatch(setGroupedDataAction({
      result: selectedData,
      name: selectedOption.value,
      label: label.current.value,
      projectId: params.id,
      filterFor: "groupAndUngroup",
    }));

    setClearSelectedRows(prevState => !prevState);
    setSelectedOption('')
    // setGroupedRows([]) // This commented line seems to be unnecessary, remove it if it's not being used.
    // reduceGrouping([]);
    dispatch(clearRowsData())
    await fetchExistingGroupNames()

    label.current.value = "";
  };



  function updateAfterGrouping() {
    const selectedUngroupRows = new Set(Object.keys(setSelectedUngroupRows));

    const newArr = keywords.filter((el) => !selectedUngroupRows.has(el.ngrams));

    setKeywords(newArr);
  }

  // Setting Pagination setting
  function setPaginationSetting(name, value) {
    let paginationSetting = JSON.parse(localStorage.getItem('paginationSetting'))
    paginationSetting[name] = { ...paginationSetting[name], limit: value, sort, sortBy };
    localStorage.setItem('paginationSetting', JSON.stringify(paginationSetting))
  }


  const getAllSelectedRows = (selectedRowsPerPage) => {
    const allSelected = Object.values(selectedRowsPerPage).filter(Boolean) // remove undefined elements
      .map((obj) => Object.values(obj)).flat();
    return allSelected;
  };


  // Grouping Ug (H2s) data
  const setUnGroupedH2sData = () => {
    const selectedData = getAllSelectedRows(setSelectedGroupRows)
    if (!selectedData.length) {
      toast.error("Kindly select any keyword", {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
      return;
    }
    updateInGkws()
    dispatch(
      setGroupedDataAction({
        result: selectedData,
        label: label.current.value,
        projectId: params.id,
        filterFor: "groupAndUgH2s"
      })
    );
    setSelectedOption('')
    setClearSelectedRows(!clearSelectedRows)
    dispatch(clearRowsData())
  };

  // Un confriming to G Kws from Ug H2s Tabs
  const unConfirmKws = () => {
    const selectedData = getAllSelectedRows(setSelectedUnGroupH2Rows)
    if (!selectedData.length) {
      toast.error("Kindly select any keyword", {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
      return;
    }
    // updateInUgh2s(selectedData);
    dispatch(
      setUnGroupedDataAction({
        result: selectedData,
        projectId: params.id,
        filterFor: "groupAndUgH2s"
      })
    );
    setClearSelectedRows(!clearSelectedRows)
    setSelectedOption('')
    dispatch(clearRowsData())
    // setUgh2sSelectedRows([])
  }

  // const updateInUgh2s = (array) => {
  //   const newArr = dataUgh2.filter(
  //     (el) => !array.some((item) => item._id === el._id)
  //   );
  //   setUgh2Data(newArr)
  // }

  // Grouping G (H2s) data
  const confirmH2s = () => {
    const selectedRows = getAllSelectedRows(setSelectedUnGroupH2Rows)
    if (!selectedRows.length) {
      toast.error("Kindly select any keyword", {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
      return;
    }
    // updateInUgh2s(selectedRows)
    dispatch(
      setGroupedDataAction({
        result: selectedRows,
        name: groupName.current.value,
        label: label.current.value,
        projectId: params.id,
        filterFor: "ugh2sAndGh2s"
      })
    );
    // setUgh2sSelectedRows([])
    setClearSelectedRows(!clearSelectedRows)
    dispatch(clearRowsData())
  };


  // Un group H2s to UG Kws from G H2s Tabs
  const ungroupH2s = () => {
    const selectedRows = getAllSelectedRows(setSelectedGroupH2Rows)
    if (!selectedRows.length) {
      toast.error("Kindly select any keyword", {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
      return;
    }
    updateInGh2s();
    dispatch(
      setUnGroupedDataAction({
        result: selectedRows,
        projectId: params.id,
        filterFor: "ugh2sAndGh2s"
      })
    );
    // setgroupedh2sSelectedRows([])
    setClearSelectedRows(!clearSelectedRows)
    dispatch(clearRowsData())
  }

  const confirmH2Orders = () => {
    const selectedRows = getAllSelectedRows(setSelectedGroupH2Rows)

    if (!selectedRows.length) {
      toast.error("Kindly select any keyword", {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
      return;
    }

    updateInGh2s();

    dispatch(
      setGroupedDataAction({
        result: selectedRows,
        projectId: params.id,
        filterFor: "gh2sAndOrderh2s"
      })
    );
    setClearSelectedRows(!clearSelectedRows)
    dispatch(clearRowsData())
  }

  const unconfirmH2Orders = () => {
    if (!selectedOrderH2Rows.length) {
      toast.error("Kindly select any keyword", {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
      return;
    }
    dispatch(
      setUnGroupedDataAction({
        result: selectedOrderH2Rows,
        projectId: params.id,
        filterFor: "gh2sAndOrderh2s"
      })
    );
    // setorderH2sSelectedRows([])
    setClearSelectedRows(!clearSelectedRows)
    dispatch(clearRowsData())
  }

  const getCount = async () => {
    let { count, countForPages } = await countService.getCount(params.id);
    const data = {
      groupsTotal: { count: count?.grouped || 0, pageCount: countForPages.group || 0 },
      UGH2sTotal: { count: count.unGroupH2sCount || 0, pageCount: countForPages.ugh2s || 0 },
      orderH2sTotal: { count: count.orderH2sCount || 0, pageCount: countForPages.orderH2s || 0 },
      groupedGH2sTotal: { count: count.groupH2sCount || 0, pageCount: countForPages.gh2s || 0 },
      total: count.ungrouped || 0,
      cleansedTotal: count.cleansed || 0
    };
    // Now set object to State
    setCounts(data)
  }

  const getFilterCountApi = async (search) => {
        let { groupsCount, ugh2sCount, ugkwCount } = await countService.getFilterCount(params.id, { ...search });
    if ((search.labelSearch.length || search.excludeLabelSearch.length) && (search.filter.length || search.include.length || search.exclude.length)) {
        setFilterCount({ groupsCount, ugh2sCount, ugkwCount })
    } 
    else if ((search.labelSearch.length || search.excludeLabelSearch.length) && !(search.filter.length || search.include.length || search.exclude.length)) {
      setFilterCount({ groupsCount: 0, ugh2sCount: 0, ugkwCount })
    } else if ((!search.labelSearch.length || !search.excludeLabelSearch.length) && (search.filter.length || search.include.length || search.exclude.length)) {
      setFilterCount({ groupsCount, ugh2sCount, ugkwCount })
    } else {
      setFilterCount({ groupsCount, ugh2sCount, ugkwCount })
    }
  }

  const handleMouseEnter = () => {
    action.fromUser = true; //this was the way I found to prevent the component to clear the selection on every page render,
    //if the user is not with the mouse on a row, doesn't allow to change the selected rows
  };

  const handleMouseLeave = () => {
    action.fromUser = false; //When the users moves the mouse out of a row, block the changes to the selected rows array (line 39)
  };

  // // Getting filter count
  // const getFilterCount = (arr) => {
  //   if (!arr[0].length && !search?.include?.length && !search?.exclude?.length && !search?.labelSearch?.length && !search?.excludeLabelSearch?.length) {
  //     return 0;
  //   }

  //   switch (selectedTab) {
  //     case '0':
  //       return ungroupFilter > 0 ? ungroupFilter : 0;
  //     case '1':
  //       return groupFilterCount > 0 ? groupFilterCount : 0;
  //     case '2':
  //       return ugH2sTotalFilter > 0 ? ugH2sTotalFilter : 0;
  //     case '3':
  //       return gh2sFilterCount > 0 ? gh2sFilterCount : 0;
  //     case '4':
  //       return orderH2sTotalFilter > 0 ? orderH2sTotalFilter : 0;
  //     case '5':
  //       return unblockedFilter > 0 ? unblockedFilter : 0;
  //     default:
  //       return 0;
  //   }
  // }

  const handlePageChange = (page, check) => {
    // if(check) {
    setBPage(page);
    // }
  };

  const Checkbox = React.forwardRef(({ _id, onClick, ...rest }, ref) => {
    return (
      <>
        <div
          className="form-check d-flex justify-content-center align-items-center ngram-checkbox"
          style={{ backgroundColor: "", cursor: "pointer" }}
        >
          <label className="form-check-label" htmlFor={_id} id={`booty-check-${_id}`}>
            <input
              type="checkbox"
              id={_id} // Add id attribute
              name={`selection-check-${_id}`} // Add name attribute
              className="form-check-input"
              style={{ height: "30px", width: "20px", cursor: "pointer" }}
              ref={ref}
              onClick={onClick}
              {...rest}
            />
          </label>
        </div>
      </>
    );
  });

  const handleLabelClick = (name) => {
    const label = labels.find(e => e.labelName === name);
    let labelSelected = selectedLabels.length ? [...selectedLabels] : [];
    if (labelSelected.includes(label._id)) {
      let newArr = labelSelected.filter(el => el !== label._id);
      dispatch(selectLabels(newArr));
      return;
    }
    else {
      return;
    }
  }

  const getLabelData = (e) => {
    setlabelSearch(e.labelSearch)
    setlabelPage(e.page)
    setlabelLimit(e.limit || 25)
  }

  const getUngroupData = (e) => {
    setSortedObj(e);
  }


  const handleInputChange = (inputValue) => {
    // Empty new Created Group Array on each change
    setNewGroupNames([]);
    // Check if the typed value already exists in the options
    const valueExists = groupNames.some((option) => option.label === inputValue);

    // If the typed value doesn't exist, create a new option and add it to the options array
    if (inputValue && !valueExists) {
      const newOption = {
        value: inputValue,
        label: inputValue,
        isNew: true
      };
      console.log(newGroupNames, "New Groups")
      setNewGroupNames([newOption]);
    }
  };


  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const tabChange = debounce((key) => {
    dispatch(setCurrentTokens([]));
    setIsTabChanging(true);
    setSelectedTab(key);
    localStorage.setItem('selectedTab', key)
  }, 100); // Adjust the debounce delay as needed

  useEffect(() => {
    setIsTabChanging(false);
  }, [selectedTab]);

  return (
    <div style={{ marginTop: '30px' }} className="row mt-2">
      <div className="col-3">
        <FileUpload triggerEffectInParent={() => setTriggerEffect(!triggerEffect)} isSuccessUpload={isSuccessUpload} socket={socket} pageName={selectedName} keywordWithAPI={keywordWithAPI} projectId={params.id} search={search}/>
        <div className="col-12 mt-4">
          <div id="scrollableContainer" className="mt-2 tokens left-panel">
            <Labels socket={socket} searching={search} keywordWithAPI={keywordWithAPI} labelData={getLabelData} />
          </div>
        </div>
      </div>
      <div className="col-7">
        <div className="filter-header">
          <div className="row justify-content-between me-1">
            <div className="col-12 d-flex  align-items-center">
              <div style={{ width: '100%' }}>
                {/* <Breadcrumbs pageName={selectedName} /> */}
                <div className="mb-2 col-12 filter-section">
                  <Row>
                    <div className="row align-items-center justify-center">
                      <div className="col-md-7 col-lg-6">
                        <FilterSection handleSearch={handleSearch} selectedName={selectedName}
                          selectedTab={selectedTab} groupName={groupName} label={label} search={search} />
                      </div>
                      <div className="col-md-5 col-lg-6">
                        <Col lg={12} className="mt-2">
                          <div className={`${selectedTab === '0' && 'single-btn'} tabs-section`} style={{ paddingTop: '3px' }}>
                            <div
                              className={`row search-page-sec d-flex justify-content-start align-items-center ${selectedTab === "1" ? "groupTab" : ""}`}
                            >
                              <input
                                type="text"
                                ref={label}
                                className="form-control col-3 border border-secondary p-2  w-35 search-pagename text-left no-radius height-50 "
                                placeholder="Label Name"
                                id="kwsLabel"
                                aria-label="LabelName"
                                aria-describedby="basic-addon1"
                              />
                              {
                                (selectedTab === '2' || selectedTab === '3' || selectedTab === '4') ?
                                  <input
                                    type="text"
                                    ref={groupName}
                                    className={`form-control col-3 border border-secondary p-2  w-35 search-pagename text-left no-radius height-25 ms-2 me-2`}
                                    placeholder={"H2 Name"}
                                    id="groupName"
                                    aria-label="Page Name"
                                    aria-describedby="basic-addon1"
                                  />
                                  :
                                  <Select
                                    className={`basic-single-select ${isMenuOpen ? "custom-menu-open" : "custom-menu-closed"}`}
                                    value={selectedOption}
                                    options={[...groupNames, ...newGroupNames]}
                                    placeholder={"Page Name"}
                                    onInputChange={handleInputChange}
                                    isCreatable // Enable creatable mode to allow adding new options
                                    // Additional props, if needed
                                    isClearable
                                    isSearchable={true}
                                    onChange={setSelectedOption}
                                    menuIsOpen={isMenuOpen} // Set the menuIsOpen prop to the isMenuOpen state
                                    onMenuOpen={handleMenuOpen} // Call the handleMenuOpen function when the menu is opened
                                    onMenuClose={handleMenuClose} // Call the handleMenuClose function when the menu is closed
                                  />
                              }

                              <div className={`${selectedTab === '0' ? "col-1" : "col-5"} ps-1 text-left d-flex`}>
                                {selectedTab === "0" ? (

                                  <Button
                                    className="btn-black group-btn"
                                    variant="btn btn-outline-dark green-bg  no-radius height-25 py-2"
                                    onClick={setGroupedData}
                                  // onAuxClick={( event ) => {
                                  //   if( event.button === 1 ) {
                                  //     setGroupedData()
                                  //   }
                                  // }}
                                  >
                                    {" "}
                                    Group{" "}
                                  </Button>

                                ) : (selectedTab === '1') ? (
                                  <>
                                    <Button
                                      className="btn-black"
                                      disabled={selectedTab == "2"}
                                      onClick={
                                        effiecientGrouping
                                      }
                                      variant="btn btn-outline-dark  no-radius height-25 py-2 bg-yellow"
                                    >
                                      Set
                                    </Button>
                                    <Button
                                      className="btn-black"
                                      disabled={selectedTab == "2"}
                                      onClick={
                                        setUnGroupedDataCAll
                                      }
                                      variant="btn btn-outline-dark  no-radius height-25 py-2 bg-yellow"
                                    >
                                      Ungroup KWs
                                    </Button>
                                    <Button
                                      className="btn-black"
                                      onClick={
                                        setUnGroupedH2sData
                                      }
                                      variant="btn btn-outline-dark  no-radius height-25 py-2 green-bg"

                                    >
                                      Confirm KWs
                                    </Button>
                                  </>

                                ) : (selectedTab === '2') ? (
                                  <>
                                    <Button
                                      className="btn-black"
                                      onClick={unConfirmKws}
                                      variant="btn btn-outline-dark  no-radius height-25 py-2 bg-yellow"
                                    >
                                      Unconfirm KWs
                                    </Button>
                                    <Button
                                      className="btn-black"
                                      variant="btn btn-outline-dark  no-radius height-25 py-2 green-bg"
                                      onClick={confirmH2s}
                                    >
                                      Group H2's
                                    </Button>
                                  </>

                                ) : (selectedTab === '3') ? (
                                  <>
                                    <Button
                                      className="btn-black"
                                      disabled={selectedTab == "2"}
                                      onClick={
                                        effiecientGroupingForGh2s
                                      }
                                      variant="btn btn-outline-dark  no-radius height-25 py-2 bg-yellow"
                                    >
                                      Set
                                    </Button>
                                    <Button
                                      className="btn-black"
                                      variant="btn btn-outline-dark  no-radius height-25 py-2 bg-yellow"
                                      onClick={ungroupH2s}
                                    >
                                      Ungroup H2s
                                    </Button>
                                    <Button
                                      className="btn-black"
                                      variant="btn btn-outline-dark  no-radius height-25 py-2 green-bg"
                                      onClick={confirmH2Orders}
                                    >
                                      Confirm H2's
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      className="btn-black"
                                      variant="btn btn-outline-dark  no-radius height-25 py-2 bg-yellow"
                                      onClick={unconfirmH2Orders}
                                    >
                                      Unconfirm H2s
                                    </Button>
                                    <Button
                                      className="btn-black"
                                      variant="btn btn-outline-dark  no-radius height-25 py-2 green-bg"
                                    >
                                      Confirm Order
                                    </Button>
                                  </>
                                )
                                }
                              </div>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </div>
                    <Col lg={6} className="filter-components main" style={{ width: "100%" }}>
                      <h4>Filters:  {search.filter.length || search.include.length || search.exclude.length || search.labelSearch.length || search.excludeLabelSearch.length ?`(${filterCount.ugkwCount} / ${filterCount.groupsCount} / ${filterCount.ugh2sCount})` : "(0 /0 /0)"}</h4>
                      {search?.filter?.length ? search.filter.split(",").map((f) => (
                        <span
                          title="Remove"
                          className={`${!f.length && 'v-hidden'}`}
                          onClick={() => {
                            handleSearch({
                              target: {
                                name: "filter",
                                value: search.filter.split(",").filter((s) => s !== f).join(',')
                              }
                            });
                          }}
                        >
                          {f}
                        </span>
                      )) : ""}

                      {search.labelFilterSearch?.length ? search.labelFilterSearch.split(",").map((f) => (
                        <span
                          title="Remove"
                          className={`${!f.length && 'v-hidden'} ${search.labelFilterSearch.includes(f) && 'light-green'}`}
                          onClick={() => {
                            handleLabelClick(f)
                          }}
                        >
                          {f}
                        </span>
                      )) : ""}


                    </Col>
                    <Row>
                      <Col md={3}>
                        <div className="main-check filter-components main d-flex" style={{ backgroundColor: "", cursor: "pointer" }}>
                          <label className="form-check-label" htmlFor="check-labelled">
                            <input
                              type="checkbox"
                              id="check-labelled"
                              onChange={() => setCheckedLabels(!checkedLabels)}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                              className="form-check-input"
                              name="check-labelled"
                              style={{ height: "18px", width: "18px", cursor: "pointer" }}
                            />
                            <h4 className="ps-2 pt-1">Show Labelled KWS</h4>
                          </label>

                        </div>
                      </Col>
                      <Col md={9}>
                        <div className="main-check filter-components main d-flex" style={{ backgroundColor: "", cursor: "pointer" }}>
                          <label className="form-check-label" htmlFor="uncheck-labelled">
                            <input
                              type="checkbox"
                              onChange={() => setCheckedUnLabels(!checkunLabels)}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                              name="uncheck-labelled"
                              id="uncheck-labelled"
                              className="form-check-input"
                              style={{ height: "18px", width: "18px", cursor: "pointer" }}
                            />
                            <h4 className="ps-2 pt-1">Show Unlabelled KWs</h4>
                          </label>
                        </div>
                      </Col  >
                    </Row>
                  </Row>

                </div>
              </div>
            </div>
          </div>
        </div>
        <Tabs
          defaultActiveKey={0}
          id="fill-tab-example"
          className="mb-1 projects-details-tab projects-kwgroup-text"
          fill
          onSelect={tabChange}
        >
          <Tab eventKey={0} title={`UG (KWs) (${counts?.total || 0})`}>
            <Suspense fallback={isTabChanging ? <div>Loading...</div> : null}>
              <UnGroup userAction={action.fromUser} getFilterCountApi={getFilterCountApi} socket={socket} total={filterCount.ugkwCount} clearSelectedRows={clearSelectedRows} setPaginationSetting={setPaginationSetting}
                keywordWithAPI={keywordWithAPI} keywords={keywords} search={search} selectedTab={selectedTab} handleSearch={handleSearch} checkedLabels={checkedLabels} sortData={getUngroupData} />
            </Suspense>
          </Tab>
          <Tab eventKey={1} title={`G (KWs) (${counts?.groupsTotal?.count || 0} ; ${counts?.groupsTotal?.pageCount || 0}) `}>
            <Suspense fallback={isTabChanging ? <div>Loading...</div> : null}>
              <Group socket={socket} getFilterCountApi={getFilterCountApi} myClass={"grouped"} clearSelectedRows={clearSelectedRows} total={groupsTotal.count} setGroupsTotal={counts?.groupsTotal} groups={groups}
                projectName={selectedName} setPaginationSetting={setPaginationSetting} search={search} selectedTab={selectedTab} keywordWithAPI={keywordWithAPI}
                sortData={getSortData} />
            </Suspense>
          </Tab>
          <Tab eventKey={2} title={`UG (H2s) (${counts?.UGH2sTotal?.count || 0} ; ${counts?.UGH2sTotal?.pageCount || 0})`}>
            <Suspense fallback={isTabChanging ? <div>Loading...</div> : null}>
              <UnGroupH2 socket={socket} getFilterCountApi={getFilterCountApi} ugH2sTotal={ugH2sTotalFilter} clearSelectedRows={clearSelectedRows} setPaginationSetting={setPaginationSetting}
                getUGH2sData={getUGH2sData} dataUgh2={dataUgh2} search={search} selectedTab={selectedTab} keywordWithAPI={keywordWithAPI} sortData={getSortData} />
            </Suspense>
          </Tab>
          <Tab eventKey={3} title={`G (H2s) (${counts?.groupedGH2sTotal?.count || 0} ; ${counts?.groupedGH2sTotal?.pageCount || 0})`}>
            <Suspense fallback={isTabChanging ? <div>Loading...</div> : null}>
              <GroupH2 socket={socket} getFilterCountApi={getFilterCountApi} filterCount={gh2sFilterCount} clearSelectedRows={clearSelectedRows} setPaginationSetting={setPaginationSetting}
                getGroupH2sData={getGroupH2sData} dataGroupH2={dataGroupH2} search={search} selectedTab={selectedTab} keywordWithAPI={keywordWithAPI} sortData={getSortData} />
            </Suspense>
          </Tab>
          <Tab eventKey={4} title={`Order (H2s) (${counts?.orderH2sTotal?.count || 0} ; ${counts?.orderH2sTotal?.pageCount || 0})`}>
            <Suspense fallback={isTabChanging ? <div>Loading...</div> : null}>
              <OrderH2 socket={socket} getFilterCountApi={getFilterCountApi} orderH2Total={orderH2sTotalFilter} setPaginationSetting={setPaginationSetting} clearSelectedRows={clearSelectedRows} getH2sOrdersData={getH2sOrdersData} h2sOrderData={h2sOrderData} search={search} selectedTab={selectedTab} />
            </Suspense>
          </Tab>
          <Tab eventKey={5} title={`Block KWs (${counts?.cleansedTotal || 0})`}>
            <Suspense fallback={isTabChanging ? <div>Loading...</div> : null}>
              <DataTable
                isLoading={isLoading || isUploading}
                className={`keywords-table`}
                paginationServer
                style={{
                  fontSize: 13,
                }}
                selectableRows
                selectableRowsComponent={Checkbox}
                columns={columns}
                onSort={sortFunction && sortFunction}
                data={blocked || []}
                // onSelectedRowsChange={onSelectedRowsChange}
                sortServer
                // clearSelectedRows={clearSelectedRows}
                pagination={true}
                fixedHeader
                onChangeRowsPerPage={(limit) => {
                  setPaginationSetting("blocked", limit)
                  setBLimit(limit);
                }
                }
                paginationRowsPerPageOptions={paginationOptions}
                paginationPerPage={blimit}
                onChangePage={handlePageChange}
                // paginationComponent={ () =>  <CustomPagination
                //   currentPage={bpage}
                //   totalPages={Math.ceil(unblockedFilter / (blimit || DEFAULT_LIMIT))}
                //   paginationRowsPerPageOptions={paginationOptions}
                //   onChangePage={handlePageChange}
                //   dataCount={unblockedFilter}
                //   itemsPerPage={blimit || DEFAULT_LIMIT} // Pass your items per page value
                //   onItemsPerPageChange={(e) => {
                //     setPaginationSetting("ungroup", parseInt(e.target.value));
                //     setLimit(parseInt(e.target.value));
                //   }}
                // />
                // }
                paginationTotalRows={unblockedFilter}
                striped
                customStyles={{
                  headRow: {
                    style: {
                      color: '#000',
                      backgroundColor: '#232323',
                      fontSize: 13,
                    },
                  },
                  rows: {
                    style: {
                      color: "#000",
                      backgroundColor: "#fff",
                      fontSize: 13,
                    },
                    stripedStyle: {
                      color: "#000",
                      backgroundColor: "#f4f4f4",
                      fontSize: 13,
                    }
                  }
                }}
              />
            </Suspense>
          </Tab>
        </Tabs>
      </div>
      <div className="col-2">
        <div className="my-2">
          <ExportSection projectId={params.id} getH2sOrdersData={getH2sOrdersData} selectedTab={selectedTab} selectedName={selectedName}
            sort={sort} sortBy={sortBy} />
        </div>
        <Ngrams projectId={params.id} search={search} setPaginationSetting={setPaginationSetting} handleSearch={handleSearch} keywordWithAPI={keywordWithAPI} labelSearch={labelSearch} labelPage={labelPage} sortedObjUG={sortedObj} labelLimit={labelLimit} getCount={getCount} />
      </div>
    </div>
  )
}

export default React.memo(KwGrouping)