import React, { useEffect, useRef, useState } from 'react'
import { MdAddCircle } from 'react-icons/md'
import LabelItem from '../components/LabelItem'
import labelService from '../../../features/labels/labelService'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getLabels, resetLabels, selectSubLabels } from '../../../features/labels/labelSlice'
import qs from "qs";
import { toast } from 'react-toastify'

const Labels = ({socket, searching, keywordWithAPI, labelData}) => {
  const params = useParams();
    const label = useRef();
    const search = useRef();

    const[page, setPage] = useState(1);
    const[labelSearch, setLabelSearch] = useState("")
    let limit = 25;

    let timeInterval;
    const { labels, selectedLabels } = useSelector((state) => state.labels)

    const dispatch = useDispatch()

    const addLabels = async () => {
        if (label.current.value.length > 0) {
            const value = label.current.value;
            await labelService.createLabel(value, params.id)
            label.current.value = ""
        } else {
            toast.warn("Label name can not be empty.", {
                autoClose: 1000,
                position: toast.POSITION.BOTTOM_LEFT
              });
        }
    }


    useEffect(() => {
        dispatch(getLabels({ id: params.id, searchParams: qs.stringify({ search: search.current.value,previousLength: labels.length, limit, page }) }))
        return () => {
            resetLabels()
        }
    }, [page]);

    useEffect(() => {
        labelData({ labelSearch: labelSearch, page:page, limit:limit })
      }, [page, labelSearch])

    useEffect(() => {
        socket.on("CREATE_LABEL" + params.id, (payload) => {
            dispatch(getLabels({ id: params.id, searchParams: qs.stringify({ search: search?.current.value,previousLength: labels.length, limit, page  }) }))
        })
        socket.on("CREATE_SUB_LABEL", (payload) => {
            dispatch(getLabels({ id: params.id, searchParams: qs.stringify({ search: search?.current.value,previousLength: labels.length, limit, page  }) }))
        })
        socket.on("DELETE_SUB_LABEL" + params.id, (payload) => {
            dispatch(getLabels({ id: params.id, searchParams: qs.stringify({ search: search?.current.value, previousLength: labels.length, limit, page }) }))
        })
        return () => {
            socket.off("CREATE_LABEL");
            socket.off("CREATE_SUB_LABEL");
        }
    }, [])

    useEffect(() => {
        const container = document.getElementById('scrollableContainer');
        container.addEventListener('scroll', handleScroll);
        return () => container.removeEventListener('scroll', handleScroll);
      }, []);

      useEffect(() => {
        if(selectedLabels.length) {
            dispatch(selectSubLabels())
        }
      }, [labels]);


    const handleScroll = () => {
        const { scrollTop, clientHeight, scrollHeight } = document.getElementById('scrollableContainer');
        if (scrollHeight - scrollTop === clientHeight) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    const handleChange = () => {
        setLabelSearch(search.current.value)
        clearTimeout(timeInterval);
        timeInterval = setTimeout(async () => {
            dispatch(getLabels({ id: params.id, searchParams: qs.stringify({ search: search.current.value, limit, page }) }))
        }, 100)
    }

    return (
        <div className='label-section'>
            <div className='d-flex justify-content-between align-items-center'>
                <div>Labels</div>
                <input className='label-name main' id='labelname' ref={label} type="text" placeholder='Label name' />
                <div className='d-flex align-items-center'>
                    <MdAddCircle className='plus-circle' style={{ cursor: "pointer", marginLeft: "8px" }} onClick={addLabels} />
                </div>
            </div>
            <div className='container label-list-view'>
                <div className='text-center mb-2'>
                    <input type="text" id='subLabelName' ref={search} className='label-name-search full-width border-radius-8' onChange={handleChange} placeholder='Search' />
                </div>
                {labels.length > 0 ? (
                    // If there are labels, render the list of LabelItems
                    labels.map((label) => (
                    <LabelItem
                        key={label.id} // Make sure to provide a unique key for each LabelItem
                        socket={socket}
                        label={label}
                        projectId={params.id}
                        search={searching}
                        keywordWithAPI={keywordWithAPI}
                    />
                    ))
                ) : (
                    // If there are no labels, show the "No records" message
                    <p className="text-center pt-4">There are no records to display.</p>
                )}
            </div>
        </div>
    )
}

export default Labels