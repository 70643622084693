import { useParams } from "react-router-dom";
import { getSocket } from "../../socket";
import { FileUpload } from "../../components/kw-grouping/components/index";
import DataTable from 'react-data-table-component';
import { useEffect, useMemo, useState } from "react";
import uploadService from '../../features/upload/uploadService';
import SliderForm from "./SliderForm";
import EditorComponent from "../../components/shared/editors/EditorComponent";
import EditorComponentOutput from "../../components/shared/editors/EditorComponentOutput";
import { DEFAULT_LIMIT_GENERATED_H2s, paginationOptionsGeneratedH2S } from "../../utils/constants";
import { Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import gh2sService from "../../features/gh2Pages/gh2PageService";
import { formatTime } from "../../utils/helper";
import LabelDeleteModal from "../shared/modals/LabelDeleteModal";
import { toast } from "react-toastify";
import * as xlsx from 'xlsx'


const socket = getSocket()

const GenerateH2Data = () => {
  const params = useParams()
  const { gptParams } = useSelector((state) => state.files)
  const gh2Id = params.id
  const [currentGh2, setCurrentGh2] = useState({});
  const [generatedH2s, setGeneratedH2s] = useState([]);
  const [stats, setStats] = useState({});
  const [limit, setLimit] = useState(
    JSON.parse(localStorage.getItem("paginationSetting"))?.generatedh2s
      ?.limit || DEFAULT_LIMIT_GENERATED_H2s
  );

  const [metrics, setMetrics] = useState({});

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [modal, setModal] = useState({
    isShow: false,
    modalFor: '',
  })


  const columns = useMemo(
    () => [
      {
        name: "#",
        cell: (row) => row.rowIndex,
        width: "3%",
        style: {
          fontSize: 11,
          paddingLeft: 15,
          color: "#212529",
          whiteSpace: 'nowrap', // Prevent wrapping 
        },
      },
      {
        name: "Generate H2 Input",
        selector: (row) => row.h2Input,
        // width: "20%",
        height: "100px",
        style: {
          fontSize: 11,
          color: "#212529",
          borderRight: "1px solid black",
        },
        cell: (row) => <EditorComponent row={row} />,
      },
      {
        name: "Generate H2 Ouput",
        selector: (row) => row.h2Output,
        // width: "20%",
        style: {
          fontSize: 11,
          color: "#212529",
          borderRight: "1px solid black",
        },
        cell: (row) => <EditorComponentOutput row={row} />,
      },
    ],
    []
  );

  async function fetchData() {
    let paginationData = { limit: limit, page: page, gh2Id };
    try {
      const result = await uploadService.getH2Generated(paginationData);
      setStats({
        averageH2sD:
          result?.averageOutputs > 0 ? result?.averageOutputs.toFixed(2) : 0,
        countH2s: result?.h2OutputCount,
      });
      const newGeneratedH2s = result?.generatedH2s || [];
      const updatedGeneratedH2s = newGeneratedH2s.map((item, index) => ({
        ...item,
        rowIndex: (page - 1) * limit + index + 1,
      }));
      setGeneratedH2s(updatedGeneratedH2s);
      setTotal(result?.total || 0);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    fetchMetricsData()
    getSingleGh2Page();
    fetchData();
  }, [page, limit]);

  const handleGPTModel = async (e) => {
    const data = { id: params.id, GPTModel: e.target.value }
    const results = await gh2sService.updateGh2Data(data)
    if (results.status) {
      getSingleGh2Page()
    }
    console.log(results, "GPT Updated Successful.")
  }

  const handleOuputH2s = async () => {
    if (currentGh2.status == 'Processing') {
      await uploadService.getAutomateH2s({ ...gptParams, gh2Id, haltExecution: true })
    } else {
      await uploadService.getAutomateH2s({ ...gptParams, gh2Id, haltExecution: false })
    }
  }

  const getSingleGh2Page = async () => {
    const gh2 = await gh2sService.getSingleGh2Page(gh2Id);
    setCurrentGh2(gh2)
  }


  const handleExport = async () => {
    const response = await uploadService.exportCSV(gh2Id)
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `GPTRESPONSE.csv`);
    document.body.appendChild(link);
    link.click();
  }

  function setPaginationSetting(name, value) {
    let paginationSetting = JSON.parse(localStorage.getItem('paginationSetting'))
    paginationSetting[name] = { ...paginationSetting[name], limit: value };
    localStorage.setItem('paginationSetting', JSON.stringify(paginationSetting))
  }


  const handlePaste = async (event) => {
    if ((event.ctrlKey || event.metaKey) && event.keyCode === 86) {
      navigator.clipboard.readText().then(async (clipboardData) => {
        // Split the data into rows (lines) based on a newline character
        const clipboardText = await navigator.clipboard.readText();
        // Parse the Excel data string
        const workbook = xlsx.read(clipboardText, { type: 'string' });
        // Assuming the data is in the first sheet of the Excel file
        const sheetName = workbook.SheetNames[0];
        console.log(workbook.Sheets[sheetName], 'this si teh sdk')
        // console.log(workbook.Sheets[sheetName], "This is the el")
        const arrayOfObjects = Object.keys(workbook.Sheets[sheetName]).map(key => ({
          h2Input: workbook.Sheets[sheetName][key].v,
        })).filter((el) => {
          console.log(el)
          if(el.h2Input !== undefined) {
            if(el.h2Input.trim() !== "")  return el
          }
        });

        // Assuming clipboardText contains tabular data in a CSV or TSV format
        // const rows = clipboardText.split('\n');
        // const array = rows.map(el => ({ h2Input: el.split('\t')[1] })).filter(el => el.h2Input.trim() !== "")
        if (arrayOfObjects.length) {
          const result = await gh2sService.generateSingleH2(params.id, { h2Inputs: arrayOfObjects });
          const newlyAddedArray = result.map((el, index) => ({ ...el, rowIndex: index + 1 }));
          toast.success(`Successfully ${arrayOfObjects.length} added through clipboard. `, {
            autoClose: 1000,
            position: toast.POSITION.BOTTOM_LEFT
          });
          setGeneratedH2s((prevGeneratedH2s) => {
            // Update the rowIndex of existing rows and calculate the correct rowIndex for newly added rows
            const updatedRows = prevGeneratedH2s.map((row, index) => ({
              ...row,
              rowIndex: index + 1 + newlyAddedArray.length,
            }));

            // Combine newlyAddedArray and updatedRows
            return [...newlyAddedArray, ...updatedRows];
          });;
        } else {
          toast.warn(`Please make sure you are copying it correctly. `, {
            autoClose: 1000,
            position: toast.POSITION.BOTTOM_LEFT
          });
        }
      });
    }
  }

  useEffect(() => {

    document.addEventListener('keydown', handlePaste);

    socket.on(`GH2_CHANGE` + params.id, (data) => {
      getSingleGh2Page();
    });

    return () => {
      socket.off(`GH2_CHANGE`);
      document.removeEventListener('keydown', handlePaste);
    };

  }, []);


  const fetchMetricsData = async () => {
    const result = await gh2sService.getMetrics(gh2Id);
    setMetrics(result)
  }

  const openModal = (modalFor) => {
    setModal(prev => ({ ...prev, isShow: true, modalFor }))
  }

  const handleDelete = async () => {
    setModal(prev => ({ ...prev, isShow: false, modalFor: '' }))
    const data = { id: params.id, startDate: null, stopDate: null, start: 0, stop: 0 }
    const result = await gh2sService.updateGh2Data(data);
    if (result.status) {
      setCurrentGh2(result.updatedDocument)
    }
  }

  const deleteOutput = async () => {
    setModal(prev => ({ ...prev, isShow: false, modalFor: '' }))
    const result = await gh2sService.deleteOutput(params.id);
    if (result.status) {
      const data = generatedH2s.map(el => ({ ...el, h2Output: "" }))
      setStats({})
      setMetrics({})
      setCurrentGh2(prev => ({ ...prev, status: 'Created' }))
      setGeneratedH2s(data)
    }
  }


  const deleteContent = async () => {
    setModal(prev => ({ ...prev, isShow: false, modalFor: '' }))
    const result = await gh2sService.deleteContent(params.id);
    handleDelete()
    if (result.status) {
      setStats({})
      setCurrentGh2(prev => ({ ...prev, status: 'Created' }))
      setTotal(0)
      setMetrics({})
      setGeneratedH2s([])
    }

  }

  const outOfRangeResults = useMemo(() => {
    return generatedH2s.filter(el => el.h2Output !== "").filter(el => el.h2Output.length <= currentGh2.maxLength).length
  }, [generatedH2s]);

  console.log(outOfRangeResults, "These are the result")

  return (
    <div className="container-fluid">
      <Row>
        <Col md={2}>

        </Col>
        <Col md={8}>
          <div className="px-5" style={{ width: "100%" }}>
            <div className="row flex-column">
              <div className="col-md-6">
                <div className="row d-flex align-items-center justify-content-between " style={{ width: "200%" }}>
                  <div className="col-md-4">
                    <FileUpload
                      socket={socket}
                      projectId={gh2Id}
                      fetchData={fetchData}
                      getSingleGh2Page={getSingleGh2Page}
                      useCheckForGenerateH2={"GenerateH2"}
                    />
                  </div>
                  <div className="col-md-3">
                    <Form.Select
                      style={{ fontSize: 13 }}
                      value={currentGh2?.GPTModel}
                      className="engine-selector"
                      aria-label="Select Engine"
                      onChange={handleGPTModel}
                    >
                      <option value="gpt-3.5-turbo">ChatGPT 3.5</option>
                      <option value="gpt-4">ChatGPT 4</option>
                    </Form.Select>
                  </div>
                  <div className="col-md-5 d-flex justify-content-between" style={{ fontSize: 13 }}>
                    <div>
                      {`${stats.countH2s || 0}/${total || 0}`}
                      <span style={{ visibility: "hidden" }}>key</span>
                      <span
                        className={`${stats.averageH2sD < 25
                          ? "text-danger"
                          : stats.averageH2sD >= 25 && stats.averageH2sD < 50
                            ? "text-warning"
                            : "text-success"
                          } `}
                      >
                        {" "}
                        <b>{`${stats.averageH2sD || 0}%`}</b>
                      </span>
                    </div>

                    <div className="d-flex">
                      <button className="btn btn-success generate processing me-3" onClick={() => openModal('OUTPUT_CLEAR')}>Remove Output</button>
                      <button className=" btn btn-success generate processing" onClick={() => openModal('CONTENT_CLEAR')}>Remove Content</button>
                    </div>
                  </div>


                </div>
              </div>
              <div className="col-md-6">
                <div className="col-md-12 d-flex justify-content-start align-items-center" style={{ width: "200%" }}>
                  <div className="col-md-3 d-flex buttons-alignments">
                    <button
                      disabled={!generatedH2s?.length || currentGh2?.status == "Processed"}
                      className={`${currentGh2.status !== "Processing"
                        ? "btn btn-success generate generate-btn"
                        : "btn btn-success generate processing"
                        }`}
                      onClick={handleOuputH2s}
                    >
                      {" "}
                      {currentGh2.status !== "Processing"
                        ? "Generate"
                        : "Stop Generating"}{" "}
                    </button>
                    <button
                      className="btn btn-success ms-2 export export-btn"
                      onClick={handleExport}
                    >
                      {" "}
                      Export{" "}
                    </button>
                  </div>
                  <div className="col-md-8">
                    <SliderForm metrics={metrics} currentGh2={currentGh2} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <DataTable
                className="keywords-table generate_H2s align-self-center"
                columns={columns}
                data={generatedH2s}
                style={{
                  fontSize: 11,
                }}
                onChangePage={(page) => {
                  setPage(page);
                }}
                onChangeRowsPerPage={(limit) => {
                  setPaginationSetting("generatedh2s", limit);
                  setLimit(limit);
                }}
                fixedHeader
                paginationServer
                paginationRowsPerPageOptions={paginationOptionsGeneratedH2S}
                paginationPerPage={limit || DEFAULT_LIMIT_GENERATED_H2s}
                paginationTotalRows={total}
                striped
                paginationDefaultPage={page}
                pagination
                customStyles={{
                  headRow: {
                    style: {
                      color: "#212529",
                      backgroundColor: "#232323",
                    },
                  },
                  rows: {
                    style: {
                      color: "#000",
                      backgroundColor: "#fff",
                    },
                    stripedStyle: {
                      color: "#000",
                      backgroundColor: "#f4f4f4",
                    },
                  },
                }}
              />
            </div>
          </div>
        </Col>
        <Col md={2}>
          <div className="row initial-stats d-flex">
            <div className="col-md-4 column1" style={{ whiteSpace: "nowrap" }}>
              <span> <strong>Start Time:</strong> </span> <br />
              <span> <strong>End Time:</strong> </span> <br />
              <span> <strong>Frequency:</strong> </span><br />
              <span> <strong>Total Retries:</strong> </span>
            </div>
            <div className="col-md-4 column2" style={{ whiteSpace: "nowrap" }}>
              <span>{formatTime(currentGh2.startDate)} </span> <br />
              <span> {formatTime(currentGh2.stopDate)} </span> <br />
              <span> {(currentGh2.start + currentGh2.stop) || 0} </span><br />
              <span> {currentGh2.retries || 0} </span>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-12 py-1">
              <button className="btn btn-success generate processing" onClick={() => openModal('CLEARING_STATS')}>
                Clear
              </button>
            </div>
          </div>
        </Col>

      </Row>
      {modal.isShow && <LabelDeleteModal show={modal} onClose={() => setModal(prev => ({ ...prev, isShow: false, modalFor: '' }))} handleConfirm={modal.modalFor === 'CLEARING_STATS' ? handleDelete : modal.modalFor === 'OUTPUT_CLEAR' ? deleteOutput : deleteContent} title={modal.modalFor === 'CLEARING_STATS' ? "Clear Stats" : 'Delete data'} text={modal.modalFor === 'CLEARING_STATS' ? "Are you sure you want to clear stats ?" : modal.modalFor === 'OUTPUT_CLEAR' ? "Are you sure you want to delete output? " : "Are you sure you want to delete content?"} />}
    </div>
  );
};

export default GenerateH2Data;
