import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import keywordService from './keywordService'

const initialState = {
  setSelectedUngroupRows: [],
  setSelectedGroupRows: [],
  setSelectedUnGroupH2Rows: [],
  setSelectedGroupH2Rows: [],
  selectedOrderH2Rows: [],
  isError: false,
  isSuccess: false,
  isCleansingSucces: false,
  inProcess: false,
  isCleansingError: false,
  isLoading: false,
  message: '',
  cleansedKeywordsProject: []
}

// Get user goals
export const cleanseKeyword = createAsyncThunk(
  'keywords/cleansing',
  async (body, thunkAPI) => {
    try {
      return await keywordService.cleansingKeyword(body)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


// setGroupedDataAction 
export const setGroupedDataAction = createAsyncThunk(
  'keywords/setGroupedDataAction',
  async (rows, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await keywordService.setGroupedDataAction(rows, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)




export const keywordSlice = createSlice({
  name: 'keywords',
  initialState,
  reducers: {
    reset: (state) => {
      state.isCleansingError = false
      state.isCleansingSucces = false
      state.isError = false
      state.isLoading = false
      state.keywords = false
      state.isSuccess = false
      state.message = ''
    },
    clearRowsData: (state, action) => {
      state.setSelectedGroupRows = []
      state.setSelectedUnGroupH2Rows = []
      state.setSelectedGroupH2Rows = []
      state.setSelectedUngroupRows = []
      state.selectedOrderH2Rows = []
    },
    setSelectedUngroup: (state, action) => {
      if (Object.keys(action.payload).length < 1) {
        state.setSelectedUngroupRows = {}
      } else {
        state.setSelectedUngroupRows = { ...state.setSelectedUngroupRows, ...action.payload }
      }
    },
    setSelectedGroup: (state, action) => {
      if (Object.keys(action.payload).length < 1) {
        state.setSelectedGroupRows = {}
      } else {
        state.setSelectedGroupRows = { ...state.setSelectedGroupRows, ...action.payload }
      }
    },
    setSelectedUnGroupH2: (state, action) => {
      state.setSelectedUnGroupH2Rows = { ...state.setSelectedUnGroupH2Rows, ...action.payload }
    },
    setSelectedGroupH2: (state, action) => {
      if (Object.keys(action.payload).length < 1) {
        state.setSelectedGroupH2Rows = {}
      } else {
        state.setSelectedGroupH2Rows = { ...state.setSelectedGroupH2Rows, ...action.payload }
      }
    },
    setSelectedOrderH2s: (state, action) => {
      state.selectedOrderH2Rows = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(cleanseKeyword.pending, (state) => {
        state.isLoading = true;
        state.inProcess = true;
        state.isCleansingError = false;
        state.isCleansingSucces = false;

      })
      .addCase(cleanseKeyword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.inProcess = false;
        state.isCleansingError = false;
        state.isCleansingSucces = true;
        state.message = action.payload.message
      })
      .addCase(cleanseKeyword.rejected, (state, action) => {
        state.isLoading = false;
        state.isCleansingError = true;
        state.inProcess = false;
        state.message = action.payload.message;
      })
      .addCase(setGroupedDataAction.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(setGroupedDataAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
      })
      .addCase(setGroupedDataAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.message = action.payload.message;
      })
  },
})

export const { reset, setSelectedOrderH2s, setSelectedUngroup, setSelectedGroup,
  setSelectedUnGroupH2, setSelectedGroupH2, clearRowsData } = keywordSlice.actions
export default keywordSlice.reducer
