import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import React, { useRef, useState } from 'react';
import { DefinedRange, createStaticRanges } from 'react-date-range';
import { Button, ButtonToolbar, OverlayTrigger, Popover } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import h2sOrderService from '../../../features/h2sOrder/h2sOrderService';
import { CSVLink } from 'react-csv';
import { format } from 'date-fns';
import { toast } from 'react-toastify'




function formatDate(date) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
}


const orderH2headers = [
    { label: "Group Name", key: "groupName" },
    { label: "Label Name", key: "label" },
    { label: "H2 Name", key: "h2Name" },
    { label: "Keywords", key: "keyword" },
    { label: "Order", key: "order" },
    { label: "Frequency", key: "frequent" },
    { label: "Export Date", key: "exportDate" },
];

const DateRangeButton = (props) => {

    const staticRanges = [
        ...createStaticRanges([
            {
                label: 'All time',
                range: () => ({
                    startDate: new Date('1900-01-01T00:00:00Z'),
                    endDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
                    filter: "All Time"
                }),
            },
            {
                label: 'Today',
                range: () => ({
                    startDate: new Date().setUTCHours(0, 0, 0, 0),
                    endDate: new Date().setUTCHours(23, 59, 59, 999),
                    filter: 'Today'
                })
            },
            {
                label: 'Yesterday',
                range: () => ({
                    startDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000).setUTCHours(0, 0, 0, 0),
                    endDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000).setUTCHours(23, 59, 59, 999),
                    filter: 'Yesterday'
                })
            },
            {
                label: 'Last 7 days',
                range: () => ({
                    startDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
                    endDate: new Date(),
                    filter: 'Last 7 days'
                })
            },
            {
                label: 'Last 30 Days',
                range: () => ({
                    startDate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
                    endDate: new Date(),
                    filter: 'Last 30 days'
                })
            },
            {
                label: 'Last 90 Days',
                range: () => ({
                    startDate: new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000),
                    endDate: new Date(),
                    filter: 'Last 90 days'
                })
            },
        ])
    ];


    const [dateRange, setDateRange] = useState(
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
            filter: "Today"
        }
    );

    const [dataForCSV, setDataCsvData] = useState([])
    const linkRef = useRef();

    const params = useParams();

    const handleSelect = (ranges) => {
        setDateRange(ranges.selection);
        getCSVDatafOrderH2s(ranges.selection)
    };


    //Fetching data for getCSVDatafOrderH2s
    async function getCSVDatafOrderH2s(dateRange) {
        let { dataForCSV } = await h2sOrderService.getCSVData(params.id, dateRange);
        if (dataForCSV.length) {
            dataForCSV.forEach(elem => {
                if (elem.label.length < 1) {
                    elem.label = "N/A";
                }
                elem.keyword = elem.keyword.replace(/^,\s*/, "");
            });
            setDataCsvData(dataForCSV)
            const searching = (JSON.parse(localStorage.getItem('search')));

            props.getH2sOrdersData(searching)
            handleDownload()
        }
        else {
            toast.warning('No data available', {
                autoClose: 1000,
                position: toast.POSITION.BOTTOM_LEFT
            });
            return
        }
    }


    const getFormattedDate = (dateRange) => {
        if (dateRange.filter === 'Today' || dateRange.filter === 'Yesterday') {
            return formatDate(dateRange.startDate)
        }
        else {
            return `${formatDate(dateRange.startDate).split(' ').slice(0, -1).concat('').join(' ')} - ${formatDate(dateRange.endDate)}`
        }
    }

    const handleDownload = () => {
        linkRef.current.link.click();
    };

    const renderStaticRangeLabel = (ranges, index, active, onClick) => {
        const label = ranges.label;
        let dateRange = null;

        // Define custom label for Today, Yesterday, and All Time ranges
        if (label === 'Today') {
            dateRange = 'Today';
        } else if (label === 'Yesterday') {
            dateRange = 'Yesterday';
        } else if (label === 'All Time') {
            dateRange = 'All Time';
        } else {
            dateRange = `${format(ranges.range1.startDate, 'MMM d')} - ${format(ranges.range1.endDate, 'MMM d')}`;
        }

        return (
            <div
                key={index}
                className={`rdrStaticRange ${active ? 'rdrStaticRangeSelected' : ''}`}
                onClick={() => onClick(index)}
            >
                {dateRange}
            </div>
        );
    };

    const popoverTop = (
        <Popover id="date-range-popover" className="custom-popover">
            <div className='header-border'>
                <div className='custom-popover-header'>
                    <p>{dateRange.filter}</p>
                    <strong>{getFormattedDate(dateRange)} </strong>
                </div>
            </div>
            <DefinedRange
                onChange={handleSelect}
                ranges={[dateRange]}
                minDate={new Date()}
                inputRanges={[]}
                staticRanges={staticRanges} // pass the custom static ranges as staticRanges prop
                renderStaticRangeLabel={renderStaticRangeLabel} // customize the appearance of static range label
                direction="horizontal"
            />
            {/* <div className='header-top-border'>
                <div className='custom-popover-footer'>
                    <p>Custom</p>
                </div>
            </div> */}
        </Popover>
    );


    return (
        <>
            <ButtonToolbar>
                <OverlayTrigger trigger="click" placement="bottom" overlay={popoverTop}>
                    <Button
                        variant="btn btn-outline-dark green-bg  no-radius height-50 "
                        className="btn-black"
                        style={{ fontSize: 11 }}

                    >Export</Button>
                </OverlayTrigger>
            </ButtonToolbar>
            <CSVLink
                headers={orderH2headers}
                className={"visibility-hidden"}
                data={dataForCSV}
                filename={'data.csv'}
                ref={linkRef}
                onClick={handleDownload}
            />
        </>
    )
}

export default DateRangeButton