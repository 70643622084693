import axios from 'axios'

const API_URL = '/api/h2orders/'


// Get H2s Orders
const getH2sOrders = async (data) => {

  const response = await axios.get(API_URL + data.id + "?" + data.query )

  return response.data
}

// Get H2s Orders
const updateH2sOrders = async (data) => {

  const response = await axios.put(API_URL, data )

  return response.data;
}

// Get H2s Orders
const getCSVData = async (id, body) => {

    const response = await axios.post(API_URL + "csvData/" + id, body )
  
    return response.data;
  }
  
const h2sOrderService = {
  getH2sOrders,
  updateH2sOrders,
  getCSVData
}

export default h2sOrderService
