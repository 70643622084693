import React from 'react'
import { Form } from 'react-bootstrap'
import debounce from 'lodash/debounce';

// const headers = [
//     { label: "Label Name", key: "label" },
//     { label: "Group Name", key: "groupName" },
//     { label: "Keywords", key: "keyword" },
//     { label: "Volume", key: "volume" },
//     { label: "Difficulty", key: "difficulty" },
//     { label: "V/D", key: "volumeRatioDiff" },
// ];

// const GH2headers = [
//     { label: "Label Name", key: "label" },
//     { label: "Group Name", key: "groupName" },
//     { label: "H2 Name", key: "h2name" },
//     { label: "Keywords", key: "keyword" },
//     { label: "Volume", key: "volume" },
//     { label: "Difficulty", key: "difficulty" },
//     { label: "V/D", key: "volumeRatioDiff" },
// ];

// const orderH2headers = [
//     { label: "Label Name", key: "label" },
//     { label: "Group Name", key: "groupName" },
//     { label: "H2 Name", key: "h2Name" },
//     { label: "Keywords", key: "keyword" },
//     { label: "Order", key: "order" },

// ];

const FilterSection = (props) => {
    const debouncedHandleSearch = debounce(props.handleSearch, 400); // Adjust the debounce delay as needed
    return (
        <Form className="col-12">
            <div className="row pt-10">
                <div className="col-12 d-flex justify-content-between align-items-center">
                    <div className="col-4 me-1">
                        <Form.Control
                            type="search"
                            placeholder="Include"
                            className="me-2 no-radius height-50"
                            aria-label="Search"
                            name="include"
                            onInput={debouncedHandleSearch}
                        />
                    </div>
                    <div className="col-2">
                        <Form.Select
                            value={props.search.includeSelect}
                            name="includeSelect"
                            onChange={props.handleSearch}
                            style={{width:"60px"}}
                            className="no-radius height-50 file-keywords-input"
                        >
                            {["All", "Any"].map((el, i) => (
                                <option key={i} value={el}>
                                    {el}
                                </option>
                            ))}
                        </Form.Select>
                    </div>
                    <div className="col-4 ms-1">
                        <Form.Control
                            type="search"
                            placeholder="Exclude"
                            className="me-2 no-radius height-50 "
                            aria-label="Search"
                            name="exclude"
                            onInput={debouncedHandleSearch}
                        />
                    </div>
                    <div className="col-2 ms-1">
                        <Form.Select
                            value={props.search.excludeSelect}
                            name="excludeSelect"
                            style={{width:"60px"}}
                            onChange={props.handleSearch}
                            className="no-radius height-50 file-keywords-input"
                        >
                            {["All", "Any"].map((el, i) => (
                                <option key={i} value={el}>
                                    {el}
                                </option>
                            ))}
                        </Form.Select>
                    </div>
                    <div className="col-12">
                        <div className="row d-flex">
                            {/* {props.selectedTab === "1" && (
                                <div className="col-2 ms-2 justify-content-end ">
                                    <Button
                                        variant="btn btn-outline-dark green-bg  no-radius height-50 "
                                        className="btn-black"
                                        style={{ zIndex: 2, position: 'relative' }}
                                    >
                                        <CSVLink
                                            headers={headers}
                                            filename={`${props.selectedName}.csv`}
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                            }}
                                            data={
                                                props.allKeywordsCsvData}
                                        >
                                            Export
                                        </CSVLink>
                                    </Button>
                                </div>
                            )}

                            {props.selectedTab === "2" && (
                                <div className="col-2 ms-2 justify-content-end ">
                                    <Button
                                        variant="btn btn-outline-dark green-bg  no-radius height-50 "
                                        className="btn-black"
                                        style={{ zIndex: 2, position: 'relative' }}
                                    >
                                        <CSVLink
                                            headers={headers}
                                            filename={`${props.selectedName}.csv`}
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                            }}
                                            data={
                                                props.csvDataForUGH2s}
                                        >
                                            Export
                                        </CSVLink>
                                    </Button>
                                </div>
                            )}

                            {props.selectedTab === "3" && (
                                <div className="col-2 ms-2 justify-content-end ">
                                    <Button
                                        variant="btn btn-outline-dark green-bg  no-radius height-50 "
                                        className="btn-black"
                                        style={{ zIndex: 2, position: 'relative' }}
                                    >
                                        <CSVLink
                                            headers={GH2headers}
                                            filename={`${props.selectedName}.csv`}
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                            }}
                                            data={
                                                props.csvDataForGroupH2s
                                            }
                                        >
                                            Export
                                        </CSVLink>
                                    </Button>
                                </div>
                            )}

                            {props.selectedTab === "4" && (
                                <div className="col-2 ms-2 justify-content-end ">
                                    <Button
                                        variant="btn btn-outline-dark green-bg  no-radius height-50 "
                                        className="btn-black"
                                        style={{ zIndex: 2, position: 'relative' }}
                                    >
                                        <CSVLink
                                            headers={orderH2headers}
                                            filename={`${props.selectedName}.csv`}
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                            }}
                                            data={
                                                props.csvDataForOrderH2s
                                            }
                                        >
                                            Export
                                        </CSVLink>
                                    </Button>
                                </div>
                            )} */}
                        </div>
                    </div>
                </div>
                
            </div>
        </Form>
    )
}

export default FilterSection