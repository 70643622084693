import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import keywordSlice from '../features/keyword/keywordSlice'
import projectSlice from '../features/project/projectSlice'
import uploadSlice from '../features/upload/uploadSlice'
import groupSlice from '../features/groups/groupSlice'
import tokenSlice from '../features/tokens/tokenSlice'
import labelSlice from '../features/labels/labelSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    projects: projectSlice,
    files: uploadSlice,
    keywords: keywordSlice,
    groups: groupSlice,
    tokens: tokenSlice,
    labels: labelSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
      immutableCheck: false,
    }),
})

