import axios from 'axios'

const API_URL = '/api/project/'

// Create new goal
const createProject = async (name) => {
  const response = await axios.post(API_URL, {name})

  return response.data
}

// Get user goals
const getProjects = async (data) => {
  const response = await axios.post(API_URL + "getProjects", data)

  return response.data
}

// Update user goals
const updateProject = async (data) => {
  const response = await axios.post(API_URL + '/update-project', {data})

  return response.data
}

// Get cleanse keywords
const getCleansedWords = async (id) => {
  const response = await axios.get(API_URL + id)

  return response.data
}

const getProject = async (id) => {
  const response = await axios.get(API_URL + 'getProject/' + id)

  return response.data
}

const deleteProject = async (data) => {
  const response = await axios.delete(API_URL + 'deleteProject/' + data.id, {data})
  return response.data
}

const updateProjectList = async (data) => {
  const response = await axios.post(API_URL + 'update-projectList/' + data.id)
  return response.data
}


const goalService = {
createProject,
getProjects,
getCleansedWords,
updateProject,
getProject,
deleteProject,
updateProjectList
}

export default goalService
