import axios from "axios";

const API_URL = "/api/upload/";

// Create new goal
const uploadCSV = async (file) => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   }

  const response = await axios.post(API_URL + "csv", file);

  return response.data;
};

// Create new goal
const uploadCSVToGenerate = async (file) => {
  const response = await axios.post(API_URL + "generate-csv", file);

  return response.data;
};

const getH2Generated = async (body) => {  
  const response = await axios.post(API_URL + "getGeneratedH2", body)

  return response.data
}

const getAutomateH2s = async (gptParams) => {  
  const response = await axios.post(API_URL + "getAutomateH2s", gptParams)

  return response.data
}

const updategh2s = async (data) => {  
  const response = await axios.put(API_URL + "updateGh2s" , data)

  return response.data
}

const exportCSV = async (id) => {  
  const response = await axios.post(API_URL + "exportCSV" , {id})

  return response.data
}

const uploadService = {
  uploadCSV,
  uploadCSVToGenerate,
  getH2Generated,
  getAutomateH2s,
  updategh2s,
  exportCSV
};

export default uploadService;
