import socketIO from 'socket.io-client';
const SOCKET_URL= process.env.REACT_APP_API_SERVER
export const getSocket = () => {
    // const token = getAuthToken(); // get jwt token from local storage or cookie
    // let user = JSON.parse(localStorage.getItem('user'))
    // if (user?.token) {
      return socketIO.connect(SOCKET_URL, {
        // query: { token:user.token },
        
          secure: true,
          transports: ['websocket', 'polling'],
          rejectUnauthorized: false,
          path: '/socket.io'
      
      });
    // }
    // return false
  };