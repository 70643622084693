import axios from "axios";

const API_URL = "/api/file/";

const getFileLogs = async (id) => {
  const response = await axios.get(API_URL + "fileLogs/" + id);

  return response.data;
};

const fileService = {
  getFileLogs,
};

export default fileService;
