// src/EditorComponent.js. 

import React, { useState } from 'react';
import { EditorState, ContentState, convertToRaw } from 'draft-js';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { truncateString } from '../../../utils/helper';
import uploadService from '../../../features/upload/uploadService';
import { convertFromHTML } from 'draft-js';

import draftToHtml from 'draftjs-to-html';
import { toast } from 'react-toastify';
import { MdFileCopy } from 'react-icons/md';


// Customize the toolbar options
const toolbarOptions = {
  options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'history'],
  inline: {
    options: ['bold', 'italic', 'underline'],
  },
  blockType: {
    options: ['Normal', 'H1', 'H2', 'H3', 'Blockquote'],
  },
  list: {
    options: ['unordered', 'ordered'],
  },
  textAlign: {
    options: ['left', 'center', 'right'],
  },
};

const EditorComponentOutput = ({ row }) => {

  const initialContentState = ContentState.createFromText(row.h2Output);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(initialContentState));
  const [isEdit, setIsEdit] = useState(false);

  const handleSave = async () => {

    // Convert editor content to raw object
    setIsEdit(false);
    const htmlOutputContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    await uploadService.updategh2s({ ...row, h2Output: htmlOutputContent })
  };

  const handleLoad = () => {

    const blocksFromHTML = convertFromHTML(row.h2Output); // Parse HTML content

    const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    const newEditorState = EditorState.createWithContent(contentState);

    setEditorState(newEditorState);
    setIsEdit(true); // Set editor to edit mode
  };


  const handleClick = () => {
    // Copy the value of row.name to the clipboard
    navigator.clipboard.writeText(truncateString(convertHtmlToPlainText(row.h2Output), 2000))
      .then(() => {
        toast.success('Copied Successfully', {
          autoClose: 1000,
          position: toast.POSITION.BOTTOM_LEFT
        });
      })
      .catch((error) => {
      });
  };

  const convertHtmlToPlainText = (html) => {
    const tempElement = document.createElement('span');
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || '';
  }

  return (
    <div>
      {
        isEdit ?
          <>
            <Editor
              editorState={editorState}
              onEditorStateChange={setEditorState}
              toolbar={toolbarOptions}
            />
            <button className='btn btn-outline-dark no-radius green-bg' onClick={handleSave}> Save </button>
          </> :
          <div className='d-flex'>
            {
              row.h2Output?.length ?
              <div style={{ alignItems: 'left', marginLeft: '-8px' }}>
              <MdFileCopy onClick={() => handleClick(row.h2Output)}
                style={{ cursor: 'pointer', width: '50px' }} />
            </div>: ""
            }
            <div onDoubleClick={handleLoad}>
              {truncateString(convertHtmlToPlainText(row.h2Output), 100)}
            </div>
          </div>
      }
    </div >
  );
};

export default EditorComponentOutput;