import React from 'react'
import { Button } from 'react-bootstrap'
import DateRangeButton from './DateRangeButton';
import keywordService from '../../../features/keyword/keywordService';

const ExportSection = (props) => {
    const handleExport = async (type) => {
        const response = await keywordService.exportData({type, projectId:props.projectId, sort:props.sort, sortBy:props.sortBy})
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${props.selectedName}.csv`);
        document.body.appendChild(link);
        link.click();
  }  
  
  
    return (
        <>
            {props.selectedTab === "0" && (
                    <div className="col-2 justify-content-start  visibilty-hidden">
                        <Button
                            variant="btn btn-outline-dark green-bg  no-radius height-50 "
                            className="btn-black"
                            style={{ fontSize :11}}
                            onClick={ () => (handleExport("group"))}
                        >
                            Export
                        </Button>
                    </div>
                )}

          {props.selectedTab === "1" && (
                    <div className="col-2 justify-content-start ">
                        <Button
                            variant="btn btn-outline-dark green-bg  no-radius height-50 "
                            className="btn-black"
                            style={{ fontSize :11}}
                            onClick={ () =>  handleExport('group')}
                        >
                        Export
                        </Button>
                    </div>
                )}

            {props.selectedTab === "2" && (
                <div className="col-2 justify-content-start ">
                    <Button
                        variant="btn btn-outline-dark green-bg  no-radius height-50 "
                        className="btn-black"
                        style={{ fontSize: 11 }}
                        onClick={ () =>  handleExport('ugh2s')}
                    >
                    Export
                    </Button>
                </div>
            )}

            {props.selectedTab === "3" && (
                <div className="col-2 justify-content-start ">
                    <Button
                        variant="btn btn-outline-dark green-bg  no-radius height-50 "
                        className="btn-black"
                        style={{ fontSize: 11 }}
                        onClick={ () =>  handleExport('gh2s')}
                    >

                            Export
                    </Button>
                </div>
            )}

            {props.selectedTab === "4" && (
                <div className="col-2 justify-content-start ">
                   <DateRangeButton getH2sOrdersData={props.getH2sOrdersData}/>
                </div>
            )}

            {props.selectedTab === "5" && (
                <div className="col-2 justify-content-start  visibilty-hidden">
                    <Button
                        variant="btn btn-outline-dark green-bg  no-radius height-50 "
                        className="btn-black"
                        style={{ fontSize: 11 }}

                    >
                        {/* <CSVLink
                            // headers={headers}
                            filename={`${props.selectedName}.csv`}
                            style={{
                                textDecoration: "none",
                                color: "black",
                            }}
                            data={
                                props.allKeywordsCsvData}
                        > */}
                            Export
                        {/* </CSVLink> */}
                    </Button>
                </div>
            )}

        </>
    )
}
export default ExportSection