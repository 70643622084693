import React from "react";
import GenerateH2Data from "./SingleGenerateContent";
import { Tab, Tabs } from "react-bootstrap";

const GernerateH2Tabs = () => {
  return (
    <Tabs
      defaultActiveKey={0}
      id="fill-tab-example"
      className="mb-3 generate-content-tabs"
      fill
    >
      <Tab eventKey={0} title={`View 1`}>
        <GenerateH2Data key={1} />
      </Tab>
      <Tab eventKey={1} title={`View 2`}>
        <h6 className="mt-5 text-center"> Comming Soon </h6>
      </Tab>
      <Tab eventKey={2} title={`View 3`}>
        <h6 className="mt-5 text-center"> Comming Soon </h6>
      </Tab>
      <Tab eventKey={3} title={`View 4`}>
        <h6 className="mt-5 text-center"> Comming Soon </h6>
      </Tab>
      <Tab eventKey={4} title={`View 5`}>
        <h6 className="mt-5 text-center"> Comming Soon </h6>
      </Tab>
    </Tabs>
  );
};

export default GernerateH2Tabs;
