import React, { useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import { FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const SidebarMenu = ({ user, isSuccess }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  
  let projectsURL = '';
  let usersURL = '/users'
  let universalMergedURL = '/universal-merged'
  let generateH2URL = '/content'

  if (isSuccess || user) {
    projectsURL = '/projects/';
  }

  const isOptionActive = (url) => {
    return window.location.pathname === url;
  };

  // const handleOptionClick = (event, url) => {
  //   if (isOptionActive(url)) {
  //     event.preventDefault(); // Prevent navigation if the option is already active
  //   }
  // };

  // const projectId = localStorage.getItem("RP_ID");

  return (
    <div style={{ display: 'inline', position: 'absolute' }}>
      <Button className="menu-outline" onClick={handleShow}>
        <FaBars />
      </Button>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Keywords</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="sidebar-custom">
          <ul>
            <li
              className={isOptionActive(usersURL) ? 'sidebar-active' : ''}>
              <Link onClick={() => setShow(false)} to="/users">Users</Link>

            </li>
            <li
              className={isOptionActive(projectsURL) ? 'sidebar-active' : ''}>
              <Link onClick={() => setShow(false)} to="/projects">Projects</Link>

            </li>
            <li
              className={isOptionActive(universalMergedURL) ? 'sidebar-active' : ''}>
              <Link onClick={() => setShow(false)} to="/universal-merged"> Universal Merge</Link>
            </li>
            <li className={isOptionActive(generateH2URL) ? 'sidebar-active' : ''}>
              <Link onClick={() => setShow(false)} to="/content">Content</Link>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default SidebarMenu;