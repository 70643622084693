import React from 'react';
const UniversalMerged = () => {
  return (
    <div className='text-center'>
      Universal Merged Page
    </div>
  );
};

export default UniversalMerged;
