export const groupSearch = (query, array) => {
  if (query.searchExclude !== "" && query.searchInclude == "") {
    if (query.excludeSelect == "All") {
      return array.map((group) => {
        return {
          ...group,
          keywords: group.keywords.filter(
            (e) =>
              !getExpressionMultiAnd(query.searchExclude.split(",")).test(
                e.keyword
              )
          ),
        };
      });
    } else if (query.excludeSelect == "Any"){
      return array.map((group) => {
        return {
          ...group,
          keywords: group.keywords.filter(
            (e) =>
              !getExpressionMulti(query.searchExclude.split(",")).test(e.keyword)
          ),
        };
      });
    }
  }
  if (query.searchExclude == "" && query.searchInclude !== "") {
    if (query.includeSelet == "All") {
      return array.map((group) => {
        return {
          ...group,
          keywords: group.keywords.filter((e) =>
            getExpressionMultiAnd(query.searchInclude.split(",")).test(
              e.keyword
            )
          ),
        };
      });
    } else if (query.includeSelet == "Any") {
      return array.map((group) => {
        return {
          ...group,
          keywords: group.keywords.filter((e) =>
          getExpressionMulti(query.searchInclude.split(",")).test(e.keyword)
          ),
        };
      });
    }
  }
  if (query.searchExclude !== "" && query.searchInclude !== "") {
    if (query.includeSelet == "All" && query.excludeSelect == "All") {
      return array.map((group) => {
        return {
          ...group,
          keywords: group.keywords.filter(
            (e) =>
              getExpressionMultiAnd(query.searchInclude.split(",")).test(
                e.keyword
              ) &&
              !getExpressionMultiAnd(query.searchExclude.split(",")).test(
                e.keyword
              )
          ),
        };
      });
    } else if (query.includeSelet == "Any" && query.excludeSelect == "Any") {
      return array.map((group) => {
        return {
          ...group,
          keywords: group.keywords.filter(
            (e) =>
            getExpressionMulti(query.searchInclude.split(",")).test(e.keyword) &&
              !getExpressionMulti(query.searchExclude.split(",")).test(e.keyword)
          ),
        };
      });
    }
  }
};
const getExpressionMulti = (keywords) => {
    const pattern = `(?:${keywords.join('|')})`;
    return new RegExp(pattern, "gi");
  };
const getExpressionMultiAnd = (keywords) => {
  const pattern = `^(?=.*${keywords.join(')(?=.*')}).+$`;
  return new RegExp(pattern, "gi");
};

export function hasUniqueKey(arr, key) {
  return arr.every((item, index) => {
    return arr.findIndex((obj) => obj[key] === item[key]) === index;
  });
}

export function truncateString(inputString, maxLength) {
  if (inputString.length <= maxLength) {
      return inputString;
  } else {
      return inputString.slice(0, maxLength - 3) + "...";
  }
}

export function formatTime(isoDateString) {
  if (!isoDateString || isoDateString == null) {
    return `00:00:00 AM`;
  }

  const date = new Date(isoDateString);

  if (isNaN(date)) {
    return `00:00:00 AM`;
  }

  const hours24 = date.getHours();
  const hours12 = hours24 % 12 || 12; // Convert to 12-hour format
  const paddedHours12 = String(hours12).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const amOrPm = hours24 < 12 ? 'AM' : 'PM';

  return `${paddedHours12}:${minutes}:${seconds} ${amOrPm}`;
}

export function formatNumber(value, type) {
  const suffixes = ["", "K", "M", "B", "T"]; // Suffixes for thousands, millions, billions, trillions, etc.

  if (type === 'volume') {
    if (value) {
      // Determine the appropriate suffix index based on the magnitude of the value
      const suffixIndex = Math.floor(Math.log10(value) / 3);

      // Calculate the scaled value
      const scaledValue = value / Math.pow(10, suffixIndex * 3);

      // Round the scaled value to the nearest whole number in thousands (1k)
      const roundedValue = Math.round(scaledValue / 1) * 1;

      return roundedValue + suffixes[suffixIndex];
    } else {
      return 0;
    }
  } else if (type === 'cpc' || type === 'cps') {
    if (value) {
      // Round the value to the nearest tenth (point one)
      const roundedValue = Math.round(value * 10) / 10;

      // Remove trailing zeros
      const formattedValue = roundedValue.toString().replace(/(\.\d*?)0+$/, '$1');

      return formattedValue;
    } else {
      return 0;
    }
  } else if (type === 'diff' || type === 'vol/diff') {
    if (value) {
      const suffixes = ["", "k", "M", "B", "T"]; // Add more suffixes as needed
      const suffixIndex = Math.floor(Math.log10(value) / 3);
    
      if (suffixIndex > 0) {
        const scaledValue = value / Math.pow(10, suffixIndex * 3);
        const roundedValue = Math.round(scaledValue);
        return roundedValue + suffixes[suffixIndex];
      } else {
        return Math.round(value);
      }
    } else {
      return 0;
    }    
  } else {
    if (value && value > 0) {
      // Determine the appropriate suffix index based on the magnitude of the value
      const suffixIndex = Math.floor(Math.log10(value) / 3);

      // Calculate the scaled value
      const scaledValue = value / Math.pow(10, suffixIndex * 3);

      // Round the scaled value to the nearest whole number
      const roundedValue = Math.round(scaledValue);

      return roundedValue + suffixes[suffixIndex];
    } else {
      return 0;
    }
  }
}

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

