import React, { useRef, useState, useEffect } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { uploadCSV, uploadCSVToGenerate } from '../../../features/upload/uploadSlice';
import { toast } from "react-toastify";
import Breadcrumbs from '../../shared/components/Breadcrumbs';
import { BsTrash } from 'react-icons/bs'
import goalService from "../../../features/project/projectService";
import { useParams } from "react-router-dom";
import * as XLSX from 'xlsx';
import PropTypes from 'prop-types';


const FileUpload = ({ triggerEffectInParent, isSuccessUpload, socket, projectId, keywordWithAPI, search, pageName, useCheckForGenerateH2, fetchData, getSingleGh2Page, }) => {
    // const [selectedFile, setSelectedFile] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const dispatch = useDispatch()
    const fileInputRef = useRef(null);
    const params = useParams();
    const [proccess, setProcess] = useState();
    const [message, setMessage] = useState();
    const [generateH2, setGenerateH2] = useState(false)

   

    const handleFileInputChange = (event) => {
        const files = event.target.files;
        const fileArray = Array.from(files);
        // Filter only the files with a ".csv" or ".xlsx" extension
        const allowedFiles = fileArray.filter(
            (file) => file.name.endsWith('.csv') || file.name.endsWith('.xlsx')
        );

        // Check if there are any files with a format other than ".csv" or ".xlsx"
        const otherFormat = fileArray.some(
            (file) => !(file.name.endsWith('.csv') || file.name.endsWith('.xlsx'))
        );  
        const uniqueFiles = allowedFiles.filter((file) => {
            return !selectedFiles.find((selectedFile) => selectedFile.name === file.name);
        });
        setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...uniqueFiles]);
          // If otherFormat is true, show an error message and return
          if (otherFormat) {
            toast.error("Only .csv and .xlsx files are allowed", {
                autoClose: 1000,
                position: toast.POSITION.BOTTOM_LEFT
            });
            return;
        }
    };

    // useEffect(() => {
    // getProject()

    // return () => {
    // }
    // }, [isSuccessUpload])

    // const getProject = async () => {
    //     const response = await goalService.getProject(params.id)
    //         if(response.status){
    //             setMessage(response.message)
    //             setProcess(response.process)
    //         }
    //         console.log("RESPONSE: ", response)
    // }

    const handleCSVUpload = ( async () => {
        if (selectedFiles.length === 0) {
            toast.error("Please select at least one file", {
                autoClose: 1000,
                position: toast.POSITION.BOTTOM_LEFT
              });
        } else {      
            const formData = new FormData();
            formData.append("projectId", projectId);
            selectedFiles.forEach((file) => {
                formData.append("csvFiles", file); // Use "csvFiles" without square brackets
            });
            
            if(generateH2){
                // const headersValid =  await verifyHeaders(selectedFiles);
                // if( headersValid ) {
                    dispatch(uploadCSVToGenerate(formData))
                    .then(() => {
                        toast.success('Upload Completed.', {
                            autoClose: 2000,
                            position: toast.POSITION.BOTTOM_LEFT
                        })
                        setSelectedFiles([]);
                        fetchData()
                        getSingleGh2Page()
                        fileInputRef.current.value = "";
                    })
                    .catch((error) => {
                        // Handle error if necessary
                        toast.success('Upload Failed.', {
                            autoClose: 2000,
                            position: toast.POSITION.BOTTOM_LEFT
                        })
                    });
                // } else {
                //     toast.error('Invalid headers. Expected: "PROMPT".', {
                //         autoClose: 1000,
                //         position: toast.POSITION.BOTTOM_LEFT
                //     })
                // }
                } else{
                    dispatch(uploadCSV(formData))
                    setSelectedFiles([]);
                    fileInputRef.current.value = "";
                    keywordWithAPI(projectId, search);
            } 
        }
    });


    // const verifyHeaders = async (selectedFiles) => {
    //     for (const file of selectedFiles) {
    //         const reader = new FileReader();
    //         const promise = new Promise((resolve) => {
    //             reader.onload = (event) => {
    //                 const data = new Uint8Array(event.target.result);
    //                 const workbook = XLSX.read(data, { type: 'array' });
    //                 const sheetName = workbook.SheetNames[0];
    //                 const worksheet = workbook.Sheets[sheetName];
    
    //                 // Extract headers from the first row
    //                 const headers = [];
    //                 for (const cellRef in worksheet) {
    //                     if (cellRef.startsWith('A1')) {
    //                         headers.push(worksheet[cellRef].v);
    //                     }
    //                 }
    
    //                 const headersToCheck = ["PROMPT"]; // Expected headers
    
    //                 // Verify headers
    //                 if (!headersToCheck.every((header) => headers.includes(header))) {
    //                     resolve(false); // Headers are not valid, resolve with false
    //                 } else {
    //                     resolve(true); // Headers are valid, resolve with true
    //                 }
    //             };
    //             reader.readAsArrayBuffer(file);
    //         });
    
    //         const result = await promise; // Wait for the current file to be processed
    //         if (!result) {
    //             return false; // If headers are not valid, return false immediately
    //         }
    //     }
    
    //     return true; // All headers are valid
    // };
    

    const handleDeleteClick = (file) => {
        const updatedFiles = selectedFiles.filter((selectedFile) => selectedFile.name !== file.name);
        if(updatedFiles.length < 1) {
            fileInputRef.current.value = ""
        }
        setSelectedFiles(updatedFiles);
    };

    useEffect(async() => {
        if(useCheckForGenerateH2?.length || useCheckForGenerateH2 != undefined || useCheckForGenerateH2 != null){
            setGenerateH2(true)
        } else {
            const response = await goalService.getProject(params.id)
            if(response.status){
                setMessage(response.message)
                setProcess(response.process)
            }
    
            socket.on("PROJECTS" + params.id , (payload) => {
                setMessage(response.message)
                setProcess(response.process)
                console.log(payload,"PROJECT DATA")
                toast.warn("File uploading is already in progress", {
                    autoClose: 1000,
                    position: toast.POSITION.BOTTOM_LEFT
                  });
            })      
        }
    }, [])

    return (
        <>
        
            {!generateH2 && <Breadcrumbs triggerEffectInParent={triggerEffectInParent} projectId={params.id} pageName={pageName} message={message} process={proccess} isSuccessUpload={isSuccessUpload}/>}
            <div className="col-md-12 d-flex justify-content-start align-items-center">
                <Form.Control
                    type="file"
                    id="fileInput"
                    placeholder="Search Keyword"
                    onChange={handleFileInputChange}
                    name="csvFiles"
                    accept=".csv, .xlsx"
                    className="me-2 no-radius height-50 w-100 d-flex justify-content-center align-items-center file-keywords-input file-upload-btn"
                    aria-label="Search"
                    ref={fileInputRef}
                    multiple

                />
                <Button
                    onClick={handleCSVUpload}
                    variant="btn btn-outline-dark no-radius height-50"
                    className="d-flex jiustify-content-center align-items-center"
                    style={{ fontSize: 13, border: '1px solid #e5e5e5' }}
                >
                    {" "}
                    Upload
                </Button>
            </div>

            <div className="col-12">

                {selectedFiles.length > 0 && (
                    <div className='mt-2'>
                        <ul className="file-list">
                            {selectedFiles.map((file, index) => (
                                <li key={index} className="file-list-item">
                                    <div style={{ wordBreak: 'break-word' }}>{file.name}</div>
                                    <div style={{ display: 'flex'}}>
                                        <div className="file-size" style={{ marginLeft: '10px'}}>{(file.size / 1024).toFixed(2)} KB</div>
                                        <div>
                                            <BsTrash onClick={() => handleDeleteClick(file)} className='delete-icon' />
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>

                )}
                {/* {selectedFiles.length > 0 && (<button className='add-more-button' onClick={handleAddMoreClick}>Add more files</button>)} */}
            </div>
        </>
    )
}

export default React.memo(FileUpload)