import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import gh2PagesService from '../../features/gh2Pages/gh2PageService'
import { Container } from 'react-bootstrap'
import DataTable from "react-data-table-component";
import { Link } from 'react-router-dom';
import { AiFillCheckCircle, AiFillDelete } from 'react-icons/ai';
import { ImSpinner10 } from 'react-icons/im';

const GH2PagesList = (props) => {


    const [name, setProjectName] = useState("");
    const [historicalSpeed, setHistoricalSpeed] = useState(0);

    const [gh2Lists, setGh2Lists] = useState([]);


    const columns =  [
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
            style: { fontSize: 11, color: "#3a3a3a" },
            width: "400px",
            cell: (row) => (<Link to={`/content/${row._id}`}> {row.name} </Link>)
        },
        {
            name: "Status",
            selector: (row) => row.message || "N/A",
            style: { fontSize: 11, color: "#3a3a3a", textAlign: "right" },
            cell: (row) => (
                <div className="" style={{ fontSize: "11px", color: row.status == "Processed" ? "#01796F" : "inherit" }}>
                    {row.status == "Processed" ? (
                        <>
                            <AiFillCheckCircle /> {row.status}
                        </>
                    ) : (
                        <>
                                <span style={{ fontSize: '11px' }}>
                                    <ImSpinner10 /> {row.status == 'Processing' ? "Processing" : "Created"}
                                </span>
                        </>
                    )}
                </div>
            )
        },
        {
            name: "Action",
            style: { fontSize: 11, color: "#3a3a3a", textAlign: "right" },
            cell: (row) => (
                <div
                    className=""
                    style={{ fontSize: "11px", cursor: "pointer", color: "#E3242B" }}
                    onClick={() => openDeleteModal(row)}
                >
                    <AiFillDelete /> Delete
                </div>
            )
        },

    ];

    useEffect(() => {
        getGh2s();
        getHistoricalSpeed()
    }, [])

    async function getGh2s() {
        const result = await gh2PagesService.getGh2Pages();
        setGh2Lists(result?.gh2Pages)
    }

    async function getHistoricalSpeed() {
        const result = await gh2PagesService.getHistoricalSpeed();
        setHistoricalSpeed(result?.historicalSpeed)
    }

    const openDeleteModal = async (row) => {
        const result = await gh2PagesService.deleteGh2(row._id)
        if(result.status == true){
            getGh2s();
            toast.success("Page Deleted Sucessfully", {
                autoClose: 1000,
                position: toast.POSITION.BOTTOM_LEFT
            });
        }
    };

    const handleFileSubmit = async () => {
        if (!name.length) {
            toast.error("Please enter name", {
                autoClose: 1000,
                position: toast.POSITION.BOTTOM_LEFT
            });
            return
        }
        const result = await gh2PagesService.createGh2Page(name);
        setProjectName("")
        if(result.status == true){
            getGh2s();
            toast.success("Page Created Sucessfully", {
                autoClose: 1000,
                position: toast.POSITION.BOTTOM_LEFT
            });
        }
    };


    return (
        <Container className='mt-5'>
            <div className='row project-screen'>
                <div className="col-8 d-flex">
                    <input
                        type="text"
                        className="form-control "
                        placeholder="Gh2 Name"
                        value={name}
                        id='projectName'
                        aria-label="Recipient's username"
                        aria-describedby="button-addon2"
                        onChange={(e) => setProjectName(e.target.value)}
                    />
                    <button className="btn btn-outline-secondary create-content-btn" type="button" onClick={handleFileSubmit} id="button-addon2">Create Content View</button>
                </div>
                <div className="col-md-4">
                    <span> <strong> Historical Speed:</strong> {historicalSpeed } </span>
                </div>
            </div>
            <div className="row pt-2" style={{ display: 'flex', justifyContent: 'center' }}>
                <DataTable
                    columns={columns}
                    data={gh2Lists}
                    className='gh2-table'
                    fixedHeader
                    style={{
                        fontSize: 11,
                        margin: '0 auto',
                    }}
                    customStyles={{
                        expanderButton: {
                            style: {
                                color: '#ccc'
                            }
                        },
                        headRow: {
                            style: {
                                color: '#000',
                                backgroundColor: '#232323',
                                position: 'sticky'
                            },
                        },
                        rows: {
                            style: {
                                color: "#000",
                                backgroundColor: "#fff"
                            },
                            stripedStyle: {
                                color: "#000",
                                backgroundColor: "#f4f4f4"
                            }
                        },
                        cells: {
                            style: {
                                textAlign: 'left' // Align all cells to the left by default
                            },
                            rightAlign: { // Custom style for right-aligned cells
                                textAlign: 'right'
                            }
                        }
                    }}
                    centered // Align columns to the center
                />
            </div>
        </Container>
    )
}

export default GH2PagesList