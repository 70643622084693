export const  paginationOptions = [ 250, 500, 1000, 1500, 2000, 2500 ]
export const  paginationOptionsGeneratedH2S = [ 300, 500, 1000, 1500, 2000, 2500 ]
export const  paginationOptionsUGKws = [ 100, 200, 300, 400, 500 ]
export const DEFAULT_LIMIT = 250;
export const DEFAULT_LIMIT_UGKWS = 100;
export const DEFAULT_LIMIT_GENERATED_H2s= 300;

export const DEFAULT_PAGINATION_SETTING = {
    ungroup: {
      limit: DEFAULT_LIMIT_UGKWS,
      page: 1
    },
    group: {
      limit: DEFAULT_LIMIT,
      page: 1
    },
    blocked: {
      limit: DEFAULT_LIMIT,
      page: 1
    },
    ugh2s: {
      limit: DEFAULT_LIMIT,
      page: 1
    },
    gh2s: {
      limit: DEFAULT_LIMIT,
      page: 1
    },
    orderh2s: {
      limit: DEFAULT_LIMIT,
      page: 1
    },
    generatedh2s: {
      limit: DEFAULT_LIMIT_GENERATED_H2s,
      page: 1
    }
  }