import axios from 'axios'

const API_URL = '/api/keywords/'


// get keywords count
const getCount = async (id) => {

  const response = await axios.get(API_URL + "count/" + id)

  return response.data
}

// get keywords filterCount
const getFilterCount = async (id, data) => {

  const response = await axios.post(API_URL + "filterCount/" + id, { data });

  return response.data
}

const countService = {
    getCount,
    getFilterCount
  }
  
  export default countService
  