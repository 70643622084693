import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import uploadService from './uploadService'


const initialState = {
  files: {},
  gptParams: {
  maxTokens: 2048, 
  temperature:1
},
  isErrorUpload: false,
  isSuccessUpload: false,
  isUploading: false,
  messageUpload: '',
}

// Register user
export const uploadCSV = createAsyncThunk(
  'files/uploadCSV',
  async (file, thunkAPI) => {
    try {
      return await uploadService.uploadCSV(file)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  })

  export const uploadCSVToGenerate = createAsyncThunk(
    'files/uploadCSVToGenerate',
    async (file, thunkAPI) => {
      try {
        return await uploadService.uploadCSVToGenerate(file)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    })


  export const uploadSlice = createSlice({
    name: 'files',
    initialState,
    reducers: {
      resetUpload: (state) => {
        state.isSuccessUpload = false
        state.isErrorUpload = false
      },
      setMaxTokensAndTemprature: (state, action) => {
        state.gptParams = {...state.gptParams, gptParams: action.payload }
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(uploadCSV.pending, (state) => {
          state.isUploading = true
        })
        .addCase(uploadCSV.fulfilled, (state, action) => {
          state.isUploading = false
          state.isSuccessUpload = true
          state.files = action.payload
        })
        .addCase(uploadCSV.rejected, (state, action) => {
          state.isUploading = false
          state.isErrorUpload = true
          state.files = null
        })
    }
  })

        
export const { resetUpload, setMaxTokensAndTemprature} = uploadSlice.actions
export default uploadSlice.reducer

