import React, {
    useMemo,
} from "react";
import DataTable from "react-data-table-component";
import { setunMergedTokens } from "../../../features/tokens/tokenSlice";
import { useDispatch, useSelector } from "react-redux";
import { formatNumber } from "../../../utils/helper";


const ExpandableRowsComponent = ({ data, isCurrentTab }) => {
    const { unmergedtokens } = useSelector((state) => state.tokens);
    const dispatch = useDispatch();

    const columns = useMemo(
        () => [
            {
                cell: (row) => {
                    // Implement your custom checkbox rendering here
                    return (
                        <Checkbox row={row} />
                    );
                },
            },
            {
                name: "Ngram",
                selector: (row) => row.token,
                sortable: true,
                style: { fontSize: 11, color: "#5a5a5a" },
            },
            {
                name: "KW",
                selector: (row) => Number(row.count),
                sortable: true,
                style: { fontSize: 11, color: "#3a3a3a" },
            },
            {
                name: "Volume",
                selector: (row) => isCurrentTab ? formatNumber(row.currentVolume) : Number(row.volume),
                sortable: true,
                style: { fontSize: 11, color: "#3a3a3a" },
            },
        ],
        [unmergedtokens]
    );

    const handleRowSelected = (row) => {
        if (Array.isArray(unmergedtokens[data.token])) {
            if (unmergedtokens[data.token].some((item => item._id === row._id))) {
                const filterUnmergedTokens = unmergedtokens[data.token].filter((item) => item._id !== row._id);
                dispatch(setunMergedTokens({ [data.token]: filterUnmergedTokens }))
            } else {
                let filterUnmergedTokens = [...unmergedtokens[data.token]]
                filterUnmergedTokens.push(row)
                dispatch(setunMergedTokens({ [data.token]: filterUnmergedTokens }))
            }
        } else {
            let obj = {[data.token] : [{...row}]} 
            dispatch(setunMergedTokens({ ...obj }))
        }
    };


    const Checkbox = ({ row }) => {

        return (
            <>
                <div
                    className="form-check d-flex justify-content-center align-items-center"
                    style={{ backgroundColor: "", cursor: "pointer" }}
                >
                    <label className="form-check-label">
                        <input
                            type="checkbox"
                            checked={Array.isArray(unmergedtokens[data.token])
                                && unmergedtokens[data.token]?.find((el) => el.token === row.token) ? true : false}
                            className={`form-check-input ${row.systemGeneratedMerged ? 'v-hidden' : ""}`}
                            name="selection-checks-UGKws"
                            onChange={() => {
                                console.log("CHECK")
                                handleRowSelected(row)
                            }}
                        />
                    </label>
                </div>
            </>
        );
    };


    return (
        <DataTable
            className="ngrams-aligned-grouped expandable-grams-grouping"
            data={data.tokens}
            columns={columns}
            noHeader={true}
            pagination
            striped
            style={{
                fontSize: 11,
            }}
            customStyles={{
                headRow: {
                    style: {
                        display: "none",
                    },
                },
                rows: {
                    style: {
                        color: "#000",
                        backgroundColor: "#fff",
                    },
                    stripedStyle: {
                        color: "#000",
                        backgroundColor: "#f4f4f4",
                    },
                },
            }}
        />
    );
};

export default React.memo(ExpandableRowsComponent);
