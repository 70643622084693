import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { setMaxTokensAndTemprature } from '../../features/upload/uploadSlice';
import Gh2s from '../../features/gh2Pages/gh2PageService'
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { formatTime } from '../../utils/helper';

const SliderForm = ({ currentGh2, metrics }) => {
  const [maxTokens, setMaxTokens] = useState(); // Initial value for max tokens
  const [temperature, setTemperature] = useState(); // Initial value for temperature
  const [minLength, setMinLength] = useState();
  const [maxLength, setMaxLength] = useState();

      
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    setMaxTokens(currentGh2?.maxTokens || 900);
    setTemperature(currentGh2?.temperature || 0.85);
    setMinLength(currentGh2?.minLength || 0);
    setMaxLength(currentGh2?.maxLength || 0);
  }, [currentGh2, metrics])

  const handleMaxTokensChange = async () => {
    if (maxTokens <= 900) {
      const data = { id: params.id, maxTokens }
      const results = await Gh2s.updateGh2Data(data)
      const updatedDAta = results.updatedDocument
      setMaxTokens(updatedDAta.maxTokens);
    } else {
      toast.error(`Please enter a value between ${0} to ${900}`, {
        autoClose: 2000,
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  const handleSaveMinLength = async () => {
    if(minLength >= 2500 || minLength >= maxLength){
        toast.error(`Value should be less than max length`, {
          autoClose: 2000,
          position: toast.POSITION.BOTTOM_LEFT
        });

        return;
      }

      let data = { id: params.id, minLength }
      const results = await Gh2s.updateGh2Data(data);
      const updatedDAta = results.updatedDocument;
      setMinLength(updatedDAta.minLength);
      toast.success(`Successfully updates the value`, {
        autoClose: 2000,
        position: toast.POSITION.BOTTOM_LEFT
      });
  };

  const handleSaveMaxLength = async () => {
    if (maxLength > 2500) {
      toast.error(`Value can not be greater than 2500`, {
        autoClose: 2000,
        position: toast.POSITION.BOTTOM_LEFT
      });
      return;
    }

    if(minLength >= maxLength) {
      
      toast.error(`Value should be greater than min length`, {
        autoClose: 2000,
        position: toast.POSITION.BOTTOM_LEFT
      });

      return;
    }
      let data = {};
      data = { id: params.id, maxLength: maxLength }
      const results = await Gh2s.updateGh2Data(data)
      const updatedDAta = results.updatedDocument
      setMaxLength(updatedDAta.maxLength);
      toast.success(`Successfully updates the value`, {
        autoClose: 2000,
        position: toast.POSITION.BOTTOM_LEFT
      });
    };

  const handleTemperatureChange = async () => {
    if (temperature <= 2) {
      const data = { id: params.id, temperature }
      const results = await Gh2s.updateGh2Data(data)
      const updatedDAta = results.updatedDocument
      setTemperature(updatedDAta.temperature);
    } else {
      toast.error(`Please enter a value between ${0} to ${2}`, {
        autoClose: 2000,
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  useEffect(() => {
    dispatch(setMaxTokensAndTemprature({ maxTokens, temperature }))
  }, [maxTokens, temperature]);

  return (
    <div className="row">
      <div className="col-md-3 d-flex justify-content-start ">
        <div className="col-md-12 d-flex justify-content-center align-items-center" style={{width: '100%'}}>
          {/* <div className="col-md-8">
            <Form.Group className="slider-group generate-slider pt-2">
              <Form.Label className="slider-label">Max Length</Form.Label>
              <Form.Control
                className="slider"
                type="range"
                min={0}
                max={2500}
                value={adjustedLength}
                onChange={(e) => setAdjustedLength(parseInt(e.target.value))}
                onMouseUp={() => {
                  handleSliderRelease("maxLength");
                }}
              />

              <Form.Text className="slider-value">{length}</Form.Text>
            </Form.Group>
          </div> */}
          <div className="col-md-6 pt-2">
            <div className='d-block'>
              <Form.Label className="slider-label" style={{whiteSpace:'nowrap'}} >Min Length</Form.Label>
              <input
                className="file-input"
                min={0}
                max={2500}
                value={minLength}
                type="number"
                placeholder='Min Length'
                onChange={(e) => setMinLength(e.target.value)}
                onBlur={handleSaveMinLength}
              />
            </div>
          </div>
          <div className="col-md-6 ms-2 pt-2">
            <div className='d-block'>
                <Form.Label className="slider-label" style={{whiteSpace:'nowrap'}} >Max Length</Form.Label>
                <input
                  className="file-input"
                  min={0}
                  max={2500}
                  value={maxLength}
                  type="number"
                  onBlur={handleSaveMaxLength}
                  placeholder='Max Length'
                  onChange={(e) => setMaxLength(e.target.value)}
                />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3 d-flex justify-content-start">
        <div className="col-md-12 d-flex justify-content-center align-items-center">
          <div className="col-md-8">
            <Form.Group className="slider-group generate-slider pt-2">
              <Form.Label className="slider-label">Max Tokens</Form.Label>
              <input
                className="file-input"
                type="number"
                
                min={0}
                max={900}
                value={maxTokens}
                onChange={(e) => setMaxTokens(parseInt(e.target.value))}
                onBlur={() => {
                  handleMaxTokensChange();
                }}
              />

              {/* <Form.Text className="slider-value">{maxTokens}</Form.Text> */}
            </Form.Group>
          </div>
          {/* <div className="col-md-3 mt-3 ms-1 ">
            <input
              className="file-input"
              min={0}
              max={900}
              value={maxTokens}
              type="number"
              onChange={handleMaxTokensChange}
            />
          </div> */}
        </div>
      </div>
      <div className="col-md-3 d-flex justify-content-start">
        <div className="col-md-12 d-flex justify-content-center align-items-center">
          <div className="col-md-8">
            <Form.Group className="slider-group generate-slider pt-2">
              <Form.Label className="slider-label">Temperature</Form.Label>
              <input
                className="file-input"
                type="number"
                min={0}
                max={2}
                step={0.1}
                value={temperature}
                onChange={(e) =>
                  setTemperature(parseFloat(e.target.value))
                }

                onBlur={() => {
                  handleTemperatureChange();
                }}
              />
              {/* <Form.Text className="slider-value">{temperature}</Form.Text> */}
            </Form.Group>
            </div>
            {/* <div className="col-md-3 mt-3 ms-1 ">
                <input  value={temperature} min={0} max={2} className='file-input' type="number" onChange={handleTemperatureChange} />
            </div> */}
            {/* <div className="col-md-4 mt-3 ms-1 ">
              <div className="row stats">
                <div className="col-md-6" style={{fontSize: '12px'}}>
                    <span><strong>Speed:</strong></span><br />
                    <span><strong>Avg Speed:</strong></span><br />
                    <span><strong>Est Time:</strong></span>
                </div>
                <div className="col-md-6" style={{fontSize: '12px'}}>
                     <span>{metrics?.currentSpeed} rows/sec</span> <br />
                     <span>{metrics?.averageSpeed?.toFixed(2) || 0} s</span> <br />
                     <span> {metrics?.estimatedTime} </span>
                </div>
              </div>
            </div> */}
        </div>
      </div>
      <div className="col-md-3 mt-2">
            <div className="row stats d-flex">
              <div className="col-md-6" style={{fontSize: '12px', whiteSpace: 'nowrap',  padding: 0 }}>
                  <span><strong>Speed (Current): </strong></span><br />
                  <span><strong>Speed (Avg): </strong></span><br />
                  <span><strong>Est Time: </strong></span><br />
                  <span><strong>Est End Time: </strong></span>
              </div>
              <div className="col-md-6" style={{fontSize: '12px', whiteSpace: 'nowrap'}}>
                    <span>{metrics?.currentSpeed || 0} rows/minute</span> <br />
                    <span>{metrics?.averageSpeed?.toFixed(2) || 0} s</span> <br />
                    <span> {metrics?.estimatedTime || '0m 0s'} </span> <br />
                    <span> {formatTime(metrics.remainingTime)} </span>
              </div>
            </div>
      </div>
    </div>
    )
}

export default SliderForm