import React, { useEffect, useMemo, useState } from 'react'
import DataTable from 'react-data-table-component';
import { formatNumber } from '../../../../utils/helper';
import NgramsExpandableRowsComponent from '../../ExpandableTabs/NgramsExpandableRowsComponent';
import { useDispatch, useSelector } from 'react-redux';
import { setunMergedTokens } from '../../../../features/tokens/tokenSlice';


const CurrenTokensTab = ({currentTokens,token, filterCurrentTokens, columns, clearSelectedRows} ) => {
    const [data, setData] = useState([])
    
    const { unmergedtokens } = useSelector((state) => state.tokens);
    const dispatch = useDispatch()

    const Checkbox = React.forwardRef(({ _id, onClick, ...rest }, ref) => {
        return (
          <>
            <div
              className="form-check d-flex justify-content-center align-items-center ngram-checkbox"
              style={{ backgroundColor: "", cursor: "pointer" }}
            >
              <label className="form-check-label" htmlFor={_id} id={`booty-check-${_id}`}>
                <input
                  type="checkbox"
                  id={_id} // Add id attribute
                  name={`selection-check-${_id}`} // Add name attribute
                  className="form-check-input"
                  style={{ height: "30px", width: "20px", cursor: "pointer" }}
                  ref={ref}
                  onClick={onClick}
                  {...rest}
                />
              </label>
            </div>
          </>
        );
      });

      useEffect(() => {
        if(token.length) {
          setData(filterCurrentTokens)
        } else {
          setData(currentTokens)
        }
      }, [currentTokens, token,filterCurrentTokens ])


      const columnss = useMemo(() => [
        columns[0],
        {
          name: 'KW',
          selector: row => row.count,
          sortable: true,
         cell: (row) => <> {formatNumber(row.count)}</>,
          style: { fontSize: 11, color: "#3a3a3a" },
          width: '30%',
        },
        {
          name: 'Volume',
          selector: row => row.currentVolume,
          sortable: true,
          cell: (row) => <> {formatNumber(row.currentVolume)}</>,
          style: { fontSize: 11, color: "#3a3a3a" },
          width: '30%',
        },
        columns[3]
      ], []);

      const onSelectedRowsChange = (e) => {
        const selectedRows = e.selectedRows;
        let obj = {};
        let unSelectedGroups = Object.keys(unmergedtokens);
    
        selectedRows.forEach((el) => {
          const index = unSelectedGroups.findIndex((s) => s === el.token); // Update to ngram property
          unSelectedGroups.splice(index, 1);
          obj[el.token] = el.tokens; // Update to ngrams property
        });
    
        dispatch(setunMergedTokens({ ...obj }));
    
        unSelectedGroups.forEach((el) => {
          dispatch(setunMergedTokens({ [el]: undefined }));
        });
    
      }
    
  return (
    <DataTable
    className={`ngrams-table keywords-table ngram `}
    style={{
      fontSize: 11,
    }}
    data={data || []}
    columns={columnss}
    selectableRows
    selectableRowsComponent={Checkbox}
    onSelectedRowsChange={onSelectedRowsChange}
    clearSelectedRows={clearSelectedRows}
    fixedHeader
    expandableRows
    expandableRowsComponent={NgramsExpandableRowsComponent}
    expandableRowsComponentProps={{isCurrentTab : true}}
    striped
    customStyles={{
      headRow: {
        style: {
          color: '#000',
          backgroundColor: '#232323'
        },
      },
      rows: {
        style: {
          color: "#000",
          backgroundColor: "#fff"
        },
        stripedStyle: {
          color: "#000",
          backgroundColor: "#f4f4f4"
        }
      }
    }}
  />
  )
}

export default CurrenTokensTab