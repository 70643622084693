import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import groupService from './groupService'
import keywordService from '../keyword/keywordService'


const initialState = {
  groups: [],
  groupMessage: '',
  groupFilterCount: 0,
  isSuccess:false,
  isError: false,
  isUngroupLoading: false,
  isSuccessGroup: false,
  isLoading: false,
  message: '',
}

// Register user
export const getGroups = createAsyncThunk(
  'groups/getGroups',
  async (data, thunkAPI) => {
    try {
      return await groupService.getGroups(data)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
 })

 export const setGroupedDataAction = createAsyncThunk(
    'group/setgroupdata',
    async (rows, thunkAPI) => {
      try {
        // const token = thunkAPI.getState().auth.user.token
        return await keywordService.setGroupedDataAction(rows)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )
  export const updateGroupedDataAction = createAsyncThunk(
    'group/update',
    async (data, thunkAPI) => {
      try {
        // const token = thunkAPI.getState().auth.user.token
        return await keywordService.updateGroupedDataAction(data)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )
  
  export const setUnGroupedDataAction = createAsyncThunk(
    'group/setungroupdata',
    async (rows, thunkAPI) => {
      try {
        // const token = thunkAPI.getState().auth.user.token
        return await keywordService.setUnGroupedDataAction(rows)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const uploadSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
      resetGroup: (state) => {
        state.groups= []
        state.isError= false
        state.isSuccessGroup= false
        state.isSuccess = false
        state.isLoading= false
        state.message= ''
        state.groupMessage = ''
      },
      setStateGroups: (state , action) => {
        state.groups =action.payload
      },
      setStateUpdatedGroups: (state , action) => {
        const {id, value} = action.payload.data;
        let groupToBeUpdated = state.groups?.map((el) => {
          if(el._id  === id) {
            return  {...el, name: value}
          } else {
            return el
          }
        })
        state.groups = groupToBeUpdated
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(getGroups.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getGroups.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.groups = action.payload.groups
          state.csvData = action.payload.dataForCSV
          state.groupFilterCount = action.payload.filterCount
        })
        .addCase(getGroups.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.files = null
        })
        // setUnGroupedDataAction
        .addCase(setGroupedDataAction.pending, (state) => {
            state.isLoading = true
          })
          .addCase(setGroupedDataAction.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
          })
          .addCase(setGroupedDataAction.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.message = action.payload.message
          })
          .addCase(setUnGroupedDataAction.pending, (state) => {
            state.isSuccessGroup = false
            state.isUngroupLoading = true
          })
          .addCase(setUnGroupedDataAction.fulfilled, (state, action) => {
            state.isUngroupLoading = false
            state.isSuccessGroup = true
            state.groupMessage = action.payload.message
          })
          .addCase(setUnGroupedDataAction.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.isUngroupLoading = false
            state.message = action.payload
            state.message = action.payload.message
          })
    }
  })

export const { reset,setStateGroups, resetGroup, setStateUpdatedGroups } = uploadSlice.actions
export default uploadSlice.reducer

