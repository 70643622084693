import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import labelService from './labelService'


const initialState = {
    labels: [],
    isSuccess: false,
    isError: false,
    isLoading: false,
    selectedLabels: [],
    selectedSublabels:[],
    message: '',
    isCompleted: false
}

// Register user
export const getLabels = createAsyncThunk(
    'labels/getLabels',
    async (data, thunkAPI) => {
        try {
            return await labelService.getLabels(data)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    })

// Register user
export const deleteLabel = createAsyncThunk(
    'labels/deleteLabels',
    async (data, thunkAPI) => {
        try {
            return await labelService.deleteLabel(data)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    })


export const labelSlice = createSlice({
    name: 'labels',
    initialState,
    reducers: {
        resetLabels: (state) => {
            state.labels = []
            state.isError = false
            state.isSuccess = false
            state.selectedLabels = []
            state.isLoading = false
            state.message = ''
        },

        handleDeleteLabels : (state, action) => {
            const labelArray = state.labels;
            const newArray = labelArray.length ? labelArray.filter(el => el._id !== action.payload) : []
            state.labels = newArray;
        },

        deleteSelectedLabels : (state, action) => {
            const labelArray = state.selectedLabels;
            const newArray = labelArray.length ? labelArray.filter(el => el !== action.payload) : []
            state.selectedLabels = newArray;
        },
        
        selectLabels: (state, action) => {
            state.selectedLabels  = action.payload
        },
        
        deleteLabels: (state, action) => {
            state.selectedLabels  = {}
        },

        selectSubLabels: (state, action) => {
            const wordsToAdd = []
            state.selectedLabels.forEach((id) => {
                const label = state.labels.find((el) => el._id === id);
                if (label) {
                  wordsToAdd.push(...label?.subLabels.map((el) => el.name));
                }
            })

            state.selectedSublabels = wordsToAdd;
        },

        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLabels.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getLabels.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.labels = action.payload.labels;
                state.isCompleted = action.payload.isCompleted
            })
            .addCase(getLabels.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
            })
            .addCase(deleteLabel.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deleteLabel.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
            })
            .addCase(deleteLabel.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
            })
    }
})

export const { resetLabels, handleDeleteLabels, selectLabels, selectSubLabels, setIsCompleted, deleteLabels, deleteSelectedLabels } = labelSlice.actions
export default labelSlice.reducer

