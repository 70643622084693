import React from 'react'
import { Modal, Button } from "react-bootstrap";

const LabelDeleteModal = ({ show, handleConfirm, title, text, onClose}) => {
  return (
    <Modal show={show}>
    <Modal.Header closeButton onClick={() => onClose()}>
        <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="alert alert-danger">
       { text }
        </div>
    </Modal.Body>
    <Modal.Footer>
        <Button variant="default" closeButton onClick={() => onClose()}>
        NO
        </Button>
        <Button variant="danger" onClick={() => {
            handleConfirm();
        }}>
        Yes
        </Button>
    </Modal.Footer>
    </Modal>
  )
}

export default LabelDeleteModal